import {
  Button,
  Input,
  VStack,
  HStack,
  Heading,
  Text,
  ButtonGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Badge,
} from "@chakra-ui/react";
import { AnimationDFA } from "../../../lib/AnimationDFA";
import { useContext, useEffect, useRef, useState } from "react";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
  * A component that contains the animation console of DFA
  * @param adfa The AnimationDFA instance
  * @param setActivated The setter of the AnimationDFA is activated or not
  */
export function AnimaticDFA({
  adfa,
  setActivated,
}: {
  adfa: AnimationDFA;
  setActivated: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isActivated = useContext(ActivationContext);

  const [speed, setSpeed] = useState<number>(1.0);
  const [input, setInput] = useState<string>("");
  const [isAuto, setAuto] = useState<boolean>(false);

  const intervalRef = useRef<NodeJS.Timeout>();
  const adfaRef = useRef<AnimationDFA>();

  /**
   * Changes the animation speed to a new speed
   * @param newSpeed A new speed
   */
  const changeSpeed = (newSpeed: number) => {
    setSpeed(newSpeed);
    if (isAuto) {
      stop();
      start();
    }
  };

  /**
   * Steps forward to the next frame of the AnimationDFA
   * @returns Nothing if the test is passed or failed
   */
  const stepForward = () => {
    if (adfaRef.current.isPassed() || adfaRef.current.isFailed()) {
      stop();
      return;
    }
    adfaRef.current.forward();
  };

  /**
   * Activates the input string test
   * @returns Nothing if the AnimationDFA is invalid
   */
  const activate = () => {
    if (!adfa.isValid()) return;
    adfa.init(input);
    setActivated(true);
  };

  /**
   * Deactivates the input string test
   */
  const deactivate = () => {
    stop();
    adfa.stop();
    setActivated(false);
  };

  /**
   * Resets the input string test
   */
  const reset = () => {
    stop();
    adfa.init(input);
  };

  /**
   * Moves backward to the previous frame of the AnimationDFA
   */
  const backward = () => {
    stop();
    adfaRef.current.backward();
  };

  /**
   * Moves forward to the next frame of the AnimationDFA
   */
  const forward = () => {
    stop();
    stepForward();
  };

  /**
   * Starts the input string test and the animation
   */
  const start = () => {
    intervalRef.current = setInterval(stepForward, 1000 / speed);
    setAuto(true);
  };

  /**
   * Stops the input string test and the animation
   */
  const stop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setAuto(false);
  };

  useEffect(() => {
    adfaRef.current = adfa;
  }, [adfa]);

  useEffect(() => {
    if (!isActivated) {
      stop();
      adfa.stop();
    }
  }, [adfa, isActivated]);

  return (
    <VStack padding="0.5rem">
      <Heading fontSize="l" textAlign="center">
        Input
      </Heading>
      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        disabled={isActivated}
        width="fit-content"
      />
      <HStack>
        {isActivated ? (
          <Button size="sm" onClick={deactivate}>
            Deactivate
          </Button>
        ) : (
          <Button size="sm" onClick={activate}>
            Activate
          </Button>
        )}
      </HStack>
      {isActivated && (
        <>
          <ButtonGroup size="sm" isAttached>
            <Button onClick={backward}>&lt;</Button>
            <Button onClick={reset}>Reset</Button>
            {isAuto ? (
              <Button onClick={stop}>Stop</Button>
            ) : (
              <Button onClick={start}>Start</Button>
            )}
            <Button onClick={forward}>&gt;</Button>
          </ButtonGroup>
          <HStack>
            <Text fontSize="sm">Speed:</Text>
            <NumberInput
              size="sm"
              maxWidth="4rem"
              min={0.5}
              max={3.0}
              step={0.5}
              value={speed}
              onChange={(_, newSpeed) => changeSpeed(newSpeed)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </HStack>
          {adfa.isPassed() ? (
            <Badge size="sm" colorScheme="green">
              Pass
            </Badge>
          ) : adfa.isFailed() ? (
            <Badge size="sm" colorScheme="red">
              Fail
            </Badge>
          ) : (
            <Badge size="sm" colorScheme="yellow">
              Running
            </Badge>
          )}
        </>
      )}
    </VStack>
  );
}
