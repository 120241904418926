import { Question } from "./Quiz";
import question1 from "../../../img/questions/question1.png";
import question2 from "../../../img/questions/question2.png";
import question3 from "../../../img/questions/question3.png";
import question4 from "../../../img/questions/question4.png";
import question5 from "../../../img/questions/question5.png";
import question6 from "../../../img/questions/question6.png";
import question7 from "../../../img/questions/question7.png";
import question8 from "../../../img/questions/question8.png";
import question9 from "../../../img/questions/question9.png";
import question10 from "../../../img/questions/question10.png";

/**
 * The question bank containing the automata questions for the quiz
 */
export const questionBank: Question[] = [
  {
    correct_answer: "PDA",
    difficulty: "medium",
    incorrect_answers: ["DFA", "NFA", "TM"],
    question: "Which Automata has a stack?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "L = {a^n b^n c^n | n ∈ N}",
    difficulty: "medium",
    incorrect_answers: [
      "L = {abc | n ∈ N}",
      "L = {a^n Xb^n Yc^nZ | n ∈ N}",
      "L = {X^n Y^n Z^n| n ∈ N}",
    ],
    question: "What is the language of this Turing Machine?",
    type: "NA",
    image: question2,
  },
  {
    correct_answer: "TM",
    difficulty: "medium",
    incorrect_answers: ["PDA", "NFA", "DFA"],
    question: "Which Automata has a tape?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "00",
    difficulty: "medium",
    incorrect_answers: ["1", "0", "01"],
    question: "Which string would be accepted by this NFA?",
    type: "NA",
    image: question3,
  },
  {
    correct_answer: "True",
    difficulty: "medium",
    incorrect_answers: ["False"],
    question: "A PDA can be non-determinstic",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "ababab",
    difficulty: "medium",
    incorrect_answers: ["aababab", "bababab", "aab"],
    question: "Which string would be accepted by this DFA?",
    type: "NA",
    image: question4,
  },
  {
    correct_answer:
      "Contains words over {a,b} containing odd number of symbols",
    difficulty: "medium",
    incorrect_answers: [
      "Contains words over {a,b} ",
      "Contains words over {a,b} containing even number of symbols",
      "Contains words over {a,b} containing more than one of each symbol",
    ],
    question: "Which describes the language of this automata best?",
    type: "NA",
    image: question5,
  },
  {
    correct_answer: "True",
    difficulty: "medium",
    incorrect_answers: ["False"],
    question: "A DFA only has one single starting state?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "False",
    difficulty: "medium",
    incorrect_answers: ["True"],
    question: "An NFA only has one single starting state?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "Pushdown Automata",
    difficulty: "medium",
    incorrect_answers: [
      "Probable Automata",
      "Pushdown Automatic",
      "Pushdown Automation",
    ],
    question: "What does PDA stand for?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "Context Free languages",
    difficulty: "medium",
    incorrect_answers: [
      "Context sensitive languages",
      "Regular Languages",
      "Decidable languages",
    ],
    question: "Which languages are equivalent to Pushdown automata?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "Regular Languages",
    difficulty: "medium",
    incorrect_answers: [
      "Context sensitive languages",
      "Context Free languages",
      "Decidable languages",
    ],
    question: "Which languages are equivalent to finite automata?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "Decidable languages",
    difficulty: "medium",
    incorrect_answers: [
      "Context sensitive languages",
      "Context Free languages",
      "Regular Languages",
    ],
    question: "Which languages are equivalent to Turing Machines?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "aaabbbbbbbbb",
    difficulty: "medium",
    incorrect_answers: ["aaabbb", "aabbbbb", "abbb"],
    question: "Which word is part of the language L = { a^n b^3n | n  > 1}?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "001011010",
    difficulty: "medium",
    incorrect_answers: ["00001", "0010111", "01101"],
    question: "Which input would be accepted by the DFA?",
    type: "NA",
    image: question1,
  },
  {
    correct_answer: "L = {0^n1^n2^n | n≥1}",
    difficulty: "medium",
    incorrect_answers: [
      "L = {a^nb^nc^n | n≥1}",
      "L = {0^n1^n2^n }",
      "L = {0^n1^n2^n | n>1}",
    ],
    question: "Can you identify the language of this Turing Machine?",
    type: "NA",
    image: question6,
  },
  {
    correct_answer: "000111222",
    difficulty: "medium",
    incorrect_answers: ["AAABBCCC", "AAABBBCCC", "00011222"],
    question: "Which input will be accepted by the Turing Machine?",
    type: "NA",
    image: question6,
  },
  {
    correct_answer: "bbbab",
    difficulty: "medium",
    incorrect_answers: ["aabbb", "bbaaa", "aab"],
    question: "Which input will be accepted by the DFA?",
    type: "NA",
    image: question7,
  },
  {
    correct_answer: "The word will always start and end with the same letter",
    difficulty: "medium",
    incorrect_answers: [
      "All words start with an a",
      "All words contain the same number of as and bs",
      "All words are at least two letters long",
    ],
    question: "Which statement is correct about this DFA?",
    type: "NA",
    image: question7,
  },
  {
    correct_answer: "Finite",
    difficulty: "medium",
    incorrect_answers: ["Infinite", "Determinstic only", "Long"],
    question: "Which would correctly describe the automata of a PDA?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "TM",
    difficulty: "medium",
    incorrect_answers: ["DFA", "NFA", "PDA"],
    question: "Which of these automata can get stuck in a loop?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "DFA",
    difficulty: "medium",
    incorrect_answers: ["PDA", "NFA", "TM"],
    question: "Can you correctly identify what type of automata this is?",
    type: "NA",
    image: question8,
  },
  {
    correct_answer: "abbca",
    difficulty: "medium",
    incorrect_answers: ["cbc", "bcbc", "bbbcc"],
    question: "Which of these inputs would be accepted in this automata?",
    type: "NA",
    image: question8,
  },
  {
    correct_answer: "True",
    difficulty: "medium",
    incorrect_answers: ["False"],
    question: "You can always convert an NFA to a DFA",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "111001",
    difficulty: "medium",
    incorrect_answers: ["1111000", "00000", "100"],
    question:
      "Can you tell which input would be accepted using this transition table?",
    type: "NA",
    image: question9,
  },
  {
    correct_answer:
      "An algorithm which can find this minimal automata for a lanaguage",
    difficulty: "medium",
    incorrect_answers: [
      "The conversion of a DFA to NFA",
      "The conversion of an NFA to DFA",
      "An algorithm that adds transitions to an automata ",
    ],
    question: "What is Minimalisation of an automata?",
    type: "NA",
    image: "",
  },
  {
    correct_answer: "DFA",
    difficulty: "medium",
    incorrect_answers: ["NFA", "TM", "PDA"],
    question: "What automata is this?",
    type: "NA",
    image: question10,
  },
  {
    correct_answer: "1010000",
    difficulty: "medium",
    incorrect_answers: ["11000", "010101", "100000"],
    question: "Which input would be accepted by this automata?",
    type: "NA",
    image: question10,
  },
];
