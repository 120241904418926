import {
  Button,
  Input,
  Stack,
  HStack,
  VStack,
  Heading,
  Kbd,
  Portal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  PopoverContent,
  PopoverTrigger,
  Tag,
  useDisclosure,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import {
  TMTapeSymbol,
  TMState,
  ReactiveTM,
  SerialTM,
} from "../../../lib/ReactiveTM";
import { useContext, useState } from "react";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
 * A component containing a popover window to set a new initial state
 * @param param0 The ReactiveTM instance
 * @returns A JSX component containing a popover window to set a new initial state
 */
function SetNewInitialStatePopover({ rtm }: { rtm: ReactiveTM }) {
  const [input, setInput] = useState<TMState>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rtm.setInitialState(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          Set
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Initial State</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to set a new blank symbol
 * @param param0 The ReactiveTM instance
 * @returns A JSX component containing a popover window to add a new final state
 */
function SetNewBlankSymbolPopover({ rtm }: { rtm: ReactiveTM }) {
  const [input, setInput] = useState<TMTapeSymbol>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rtm.setBlankSymbol(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          Set
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Blank Symbol</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new final state
 * @param param0 The ReactiveTM instance
 * @returns A JSX component containing a popover window to add a new final state
 */
function AddNewFinalStatePopover({ rtm }: { rtm: ReactiveTM }) {
  const [input, setInput] = useState<TMState>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rtm.addFinalState(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Final State</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new symbol
 * @param param0 The ReactiveTM instance
 * @returns A JSX component containing a popover window to add a new symbol
 */
function AddNewSymbolPopover({ rtm }: { rtm: ReactiveTM }) {
  const [input, setInput] = useState<TMTapeSymbol>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rtm.addSymbol(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Symbol</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new tape symbol
 * @param param0 The ReactiveTM instance
 * @returns A JSX component containing a popover window to add a new tape symbol
 */
function AddNewTapeSymbolPopover({ rtm }: { rtm: ReactiveTM }) {
  const [input, setInput] = useState<TMTapeSymbol>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rtm.addTapeSymbol(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Tape Symbol</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing the debug console of TM
 * @param param0 The SerialTM and ReactiveTM instances
 * @returns A JSX component containing the debug console of TM
 */
export function ConsoleTM({ stm, rtm }: { stm: SerialTM; rtm: ReactiveTM }) {
  const isActivated = useContext(ActivationContext);

  return (
    <VStack padding="0.5rem">
      <Heading fontSize="l">Initial State</Heading>
      <HStack>
        {stm.initialState !== undefined && <Tag>{stm.initialState}</Tag>}
        {!isActivated && <SetNewInitialStatePopover rtm={rtm} />}
      </HStack>
      <Heading fontSize="l">Blank Symbol</Heading>
      <HStack>
        {stm.blankSymbol !== undefined && (
          <Kbd userSelect="none">
            {stm.blankSymbol === "" ? "ε" : stm.blankSymbol}
          </Kbd>
        )}
        {!isActivated && <SetNewBlankSymbolPopover rtm={rtm} />}
      </HStack>
      <Heading fontSize="l">Final States</Heading>
      <HStack>
        {stm.finalStates.map((state) => (
          <Tag key={state}>
            <TagLabel>{state}</TagLabel>
            {!isActivated && (
              <TagCloseButton onClick={() => rtm.deleteFinalState(state)} />
            )}
          </Tag>
        ))}
        {!isActivated && <AddNewFinalStatePopover rtm={rtm} />}
      </HStack>
      <Heading fontSize="l">Symbols</Heading>
      <HStack>
        {stm.symbols.map((symbol) => (
          <Kbd
            userSelect="none"
            key={symbol}
            onDoubleClick={
              isActivated ? () => {} : () => rtm.deleteSymbol(symbol)
            }
          >
            {symbol === "" ? "ε" : symbol}
          </Kbd>
        ))}
        {!isActivated && <AddNewSymbolPopover rtm={rtm} />}
      </HStack>
      <Heading fontSize="l">Tape Symbols</Heading>
      <HStack>
        {stm.tapeSymbols.map((symbol) => (
          <Kbd
            userSelect="none"
            key={symbol}
            onDoubleClick={
              isActivated ? () => {} : () => rtm.deleteTapeSymbol(symbol)
            }
          >
            {symbol === "" ? "ε" : symbol}
          </Kbd>
        ))}
        {!isActivated && <AddNewTapeSymbolPopover rtm={rtm} />}
      </HStack>
    </VStack>
  );
}
