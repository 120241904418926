import {
  Button,
  Input,
  Stack,
  HStack,
  VStack,
  Heading,
  Kbd,
  Portal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tag,
  Tbody,
  Td,
  Thead,
  Tr,
  useDisclosure,
  TagLabel,
  TagCloseButton,
  Editable,
  EditablePreview,
  EditableInput,
} from "@chakra-ui/react";
import {
  NFAInputSymbol,
  NFAState,
  ReactiveNFA,
  SerialNFA,
} from "../../../lib/ReactiveNFA";
import { useContext, useEffect, useState } from "react";
import { ObjectMap } from "../../../lib/util/ObjectMap";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
 * A component containing a popover window to set a new initial state
 * @param param0 The ReactiveNFA instance
 * @returns A JSX component containing a popover window to set a new initial state
 */
function AddNewInitialStatePopover({ rnfa }: { rnfa: ReactiveNFA }) {
  const [input, setInput] = useState<NFAState>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rnfa.addInitialState(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Initial State</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new final state
 * @param param0 The ReactiveNFA instance
 * @returns A JSX component containing a popover window to add a new final state
 */
function AddNewFinalStatePopover({ rnfa }: { rnfa: ReactiveNFA }) {
  const [input, setInput] = useState<NFAState>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rnfa.addFinalState(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Final State</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new input symbol
 * @param param0 The ReactiveNFA instance
 * @returns A JSX component containing a popover window to add a new input symbol
 */
function AddNewInputSymbolPopover({ rnfa }: { rnfa: ReactiveNFA }) {
  const [input, setInput] = useState<NFAInputSymbol>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rnfa.addInputSymbol(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Input Symbol</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing an editable output state of a transition
 * @param param0 The ReactiveNFA instance and NFATransition
 * @returns A JSX component containing an editable output state of a transition
 */
function OutputStateEditable({
  rnfa,
  inputState,
  inputSymbol,
  outputStates,
}: {
  rnfa: ReactiveNFA;
  inputState: NFAState;
  inputSymbol: NFAInputSymbol;
  outputStates: readonly NFAState[];
}) {
  const isActivated = useContext(ActivationContext);
  const [input, setInput] = useState<NFAState>("");

  useEffect(() => {
    setInput(outputStates.toString());
  }, [outputStates]);

  const background = outputStates.length > 0 ? "" : "yellow.200";
  const color = background !== "" ? "black" : "";

  return (
    <Editable
      value={input || "?"}
      isDisabled={isActivated}
      onChange={(nextValue) => setInput(nextValue)}
      onSubmit={() => {
        if (input === "") {
          rnfa.modifyTransitionOutput([[inputState, inputSymbol]], []);
        } else {
          rnfa.modifyTransitionOutput(
            [[inputState, inputSymbol]],
            input.split(","),
          );
        }
        setInput(outputStates.toString());
      }}
      width="100%"
    >
      <EditablePreview
        width="100%"
        background={background}
        color={color}
        minWidth="2rem"
        textAlign="center"
      />
      <EditableInput width="100%" textAlign="center" />
    </Editable>
  );
}

/**
 * A component containing the debug console of NFA
 * @param param0 The SerialNFA and ReactiveNFA instances
 * @returns A JSX component containing the debug console of NFA
 */
export function ConsoleNFA({
  snfa,
  rnfa,
}: {
  snfa: SerialNFA;
  rnfa: ReactiveNFA;
}) {
  const isActivated = useContext(ActivationContext);

  const tabulate = (): [
    NFAState,
    [NFAInputSymbol, readonly NFAState[]][],
  ][] => {
    const map = new ObjectMap(snfa.transitions);
    return snfa.states.map((state) => [
      state,
      snfa.inputSymbols.map((symbol) => [symbol, map.get([state, symbol])]),
    ]);
  };

  return (
    <VStack padding="0.5rem">
      <Heading fontSize="l">Initial States</Heading>
      <HStack>
        {snfa.initialStates.map((state) => (
          <Tag key={state}>
            <TagLabel>{state}</TagLabel>
            {!isActivated && (
              <TagCloseButton onClick={() => rnfa.deleteInitialState(state)} />
            )}
          </Tag>
        ))}
        {!isActivated && <AddNewInitialStatePopover rnfa={rnfa} />}
      </HStack>
      <Heading fontSize="l">Final States</Heading>
      <HStack>
        {snfa.finalStates.map((state) => (
          <Tag key={state}>
            <TagLabel>{state}</TagLabel>
            {!isActivated && (
              <TagCloseButton onClick={() => rnfa.deleteFinalState(state)} />
            )}
          </Tag>
        ))}
        {!isActivated && <AddNewFinalStatePopover rnfa={rnfa} />}
      </HStack>
      <Heading fontSize="l">Input Symbols</Heading>
      <HStack>
        {snfa.inputSymbols.map((symbol) => (
          <Kbd
            userSelect="none"
            key={symbol}
            onDoubleClick={
              isActivated ? () => {} : () => rnfa.deleteInputSymbol(symbol)
            }
          >
            {symbol === "" ? "ε" : symbol}
          </Kbd>
        ))}
        {!isActivated && <AddNewInputSymbolPopover rnfa={rnfa} />}
      </HStack>
      {snfa.inputSymbols.length > 0 && (
        <>
          <Heading fontSize="l">Transition Table</Heading>
          <Table size="sm">
            <Thead>
              <Tr>
                <Td>δ</Td>
                {snfa.inputSymbols.map((symbol) => (
                  <Td key={symbol} textAlign="center">
                    {symbol === "" ? "ε" : symbol}
                  </Td>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {tabulate().map(([inputState, transitions]) => (
                <Tr key={inputState}>
                  {/* TODO: mark initialState and finalState */}
                  <Td>{inputState}</Td>
                  {transitions.map(([inputSymbol, outputStates]) => (
                    <Td key={`${inputState}-${inputSymbol}`}>
                      <OutputStateEditable
                        rnfa={rnfa}
                        inputState={inputState}
                        inputSymbol={inputSymbol}
                        outputStates={outputStates || []}
                      />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </VStack>
  );
}
