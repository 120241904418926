import { useState } from "react";

import { ObjectMap } from "./util/ObjectMap";
import { PointXY } from "./util/PointXY";
import { AutomatonType, DataNFA } from "./util/AutomatonType";

/**
 * A non-deterministic finite automata (NFA) state
 */
export type NFAState = string;
/**
 * A symbol in the NFA's input alphabet
 */
export type NFAInputSymbol = string;
/**
 * A NFA transition input: a state and an input symbol
 */
export type NFATransitionInput = readonly [NFAState, NFAInputSymbol];
/**
 * A NFA transition output: a state
 */
export type NFATransitionOutput = NFAState;
/**
 * A unique identifier for a NFA, consisting of an initial state and an array of input symbols
 */
export type NFAID = readonly [NFAState, readonly NFAInputSymbol[]];

/**
 * Defination of NFA
 */
export type NFA = {
  /**
   * A finite set of states Q
   */
  readonly states: Set<NFAState>;
  /**
   * A finite set of input symbols, the alphabet, Σ
   */
  readonly inputSymbols: Set<NFAInputSymbol>;
  /**
   * A transition function δ ∈ Q × Σ → P(Q)
   */
  readonly transitions: ObjectMap<
    NFATransitionInput,
    readonly NFATransitionOutput[]
  >;
  /**
   * A set of initial states S ⊆ Q
   */
  readonly initialStates: Set<NFAState>;
  /**
   * A set of final (or accepting) states F ⊆ Q
   */
  readonly finalStates: Set<NFAState>;
};

/**
 * Defination of SerialNFA
 */
export type SerialNFA = {
  /**
   * The array of states in the NFA
   */
  readonly states: readonly NFAState[];
  /**
   * The array of input symbols in the NFA's alphabet
   */
  readonly inputSymbols: readonly NFAInputSymbol[];
  /**
   * The array of transitions in the NFA, where each transition is represented
   * as a tuple of a NFATransitionInput and an array of NFATransitionOutput
   */
  readonly transitions: readonly [
    NFATransitionInput,
    readonly NFATransitionOutput[],
  ][];
  /**
   * The array of initial states in the NFA
   */
  readonly initialStates: readonly NFAState[];
  /**
   * The array of final states in the NFA
   */
  readonly finalStates: readonly NFAState[];
};

/**
 * Hook that can be used to manipulate SerialNFA
 */
export type HookSerialNFA = {
  readonly setStates: React.Dispatch<React.SetStateAction<readonly NFAState[]>>;
  readonly setInputSymbols: React.Dispatch<
    React.SetStateAction<readonly NFAInputSymbol[]>
  >;
  readonly setTransitions: React.Dispatch<
    React.SetStateAction<
      readonly [NFATransitionInput, readonly NFATransitionOutput[]][]
    >
  >;
  readonly setInitialStates: React.Dispatch<
    React.SetStateAction<readonly NFAState[]>
  >;
  readonly setFinalStates: React.Dispatch<
    React.SetStateAction<readonly NFAState[]>
  >;
};

/**
 * A mapping from NFAState to PointXY
 */
export type PositionTableNFA = ObjectMap<NFAState, PointXY>;
/**
 * An array of tuples, where each tuple contains an NFAState and its corresponding PointXY position
 */
export type PositionsNFA = readonly (readonly [NFAState, PointXY])[];
/**
 * Hook of PositionsNFA
 */
export type HookPositionsNFA = React.Dispatch<
  React.SetStateAction<PositionsNFA>
>;

/**
 * The defination of ReactiveNFA
 */
export class ReactiveNFA {
  /**
   * A non-deterministic finite automata (NFA)
   */
  private nfa: NFA;
  /**
   * A hook of SerialNFA
   */
  private readonly hook: HookSerialNFA;
  /**
   *  A Position Table of NFA
   */
  private ptnfa: PositionTableNFA;
  /**
   * A Hook of positions of NFA
   */
  private readonly hookPNFA: HookPositionsNFA;

  /**
   * Constructs a ReactiveNFA
   * @param nfa A non-deterministic finite automata (NFA)
   * @param hook A hook of SerialNFA
   * @param ptnfa A Position Table of NFA
   * @param hookPNFA A Hook of positions of NFA
   */
  public constructor(
    nfa: NFA,
    hook: HookSerialNFA,
    ptnfa: PositionTableNFA,
    hookPNFA: HookPositionsNFA,
  ) {
    this.nfa = nfa;
    this.hook = hook;
    this.ptnfa = ptnfa;
    this.hookPNFA = hookPNFA;
  }

  /**
   * Imports data to the NFA
   * @param param0 The Data to be imported
   */
  public importData(
    {
      automaton: {
        states,
        inputSymbols,
        transitions,
        initialStates,
        finalStates,
      },
      positions,
    }: DataNFA = {
      type: AutomatonType.NFA,
      automaton: {
        states: [],
        inputSymbols: [],
        transitions: [],
        initialStates: [],
        finalStates: [],
      },
      positions: [],
    },
  ): void {
    this.nfa = {
      states: new Set(states),
      inputSymbols: new Set(inputSymbols),
      transitions: new ObjectMap(transitions),
      initialStates: new Set(initialStates),
      finalStates: new Set(finalStates),
    };
    this.ptnfa = new ObjectMap(positions);

    this.hook.setStates(Array.from(this.nfa.states));
    this.hook.setInputSymbols(Array.from(this.nfa.inputSymbols));
    this.hook.setTransitions(Array.from(this.nfa.transitions));
    this.hook.setInitialStates(Array.from(this.nfa.initialStates));
    this.hook.setFinalStates(Array.from(this.nfa.finalStates));
    this.hookPNFA(Array.from(this.ptnfa));
  }

  /**
   * Adds a state to the NFA
   * @param state A NFA state
   */
  public addState(state: NFAState): void {
    this.nfa.states.add(state);
    this.hook.setStates(Array.from(this.nfa.states));

    this.ptnfa.set(state, [0, 0]);
    this.hookPNFA(Array.from(this.ptnfa));
  }

  /**
   * Deletes a state in the NFA
   * @param state A NFA state
   */
  public deleteState(state: NFAState): void {
    this.nfa.states.delete(state);
    this.hook.setStates(Array.from(this.nfa.states));

    for (const [[inputState, inputSymbol], outputStates] of this.nfa
      .transitions) {
      if (inputState === state) {
        this.nfa.transitions.delete([inputState, inputSymbol]);
      } else {
        this.nfa.transitions.set(
          [inputState, inputSymbol],
          outputStates.filter((s) => s !== state),
        );
      }
    }
    this.hook.setTransitions(Array.from(this.nfa.transitions));

    this.nfa.initialStates.delete(state);
    this.hook.setInitialStates(Array.from(this.nfa.initialStates));

    this.nfa.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.nfa.finalStates));

    this.ptnfa.delete(state);
    this.hookPNFA(Array.from(this.ptnfa));
  }

  /**
   * Renames a state in the NFA
   * @param oldState The old NFA state
   * @param newState The new NFA state
   * @returns Nothing if the new NFA state is already existed
   */
  public renameState(oldState: NFAState, newState: NFAState): void {
    if (this.nfa.states.has(newState)) return;
    // create a new state
    this.addState(newState);
    this.setPosition(newState, this.ptnfa.get(oldState));
    if (this.nfa.initialStates.has(oldState)) this.addInitialState(newState);
    if (this.nfa.finalStates.has(oldState)) this.addFinalState(newState);
    // migrate transitions from and to the state
    for (const [[oldInputState, inputSymbol], oldOutputStates] of this.nfa
      .transitions) {
      const isInput = oldInputState === oldState;
      const isOutput = oldOutputStates.includes(oldState);
      if (isInput || isOutput) {
        const newInputState = isInput ? newState : oldInputState;
        const newOutputStates = new Set(oldOutputStates);
        if (isOutput) {
          newOutputStates.delete(oldState);
          newOutputStates.add(newState);
        }
        for (const outputState of oldOutputStates) {
          this.deleteTransition([[oldInputState, inputSymbol], outputState]);
        }
        for (const outputState of newOutputStates) {
          this.addTransition([[newInputState, inputSymbol], outputState]);
        }
      }
    }
    // delete the old state
    this.deleteState(oldState);
  }

  /**
   * Adds an input symbol to the NFA
   * @param symbol An NFA input symbol
   */
  public addInputSymbol(symbol: NFAInputSymbol): void {
    this.nfa.inputSymbols.add(symbol);
    this.hook.setInputSymbols(Array.from(this.nfa.inputSymbols));
  }

  /**
   * Deletes an input symbol in the NFA
   * @param symbol An NFA input symbol
   */
  public deleteInputSymbol(symbol: NFAInputSymbol): void {
    this.nfa.inputSymbols.delete(symbol);
    this.hook.setInputSymbols(Array.from(this.nfa.inputSymbols));

    for (const [[inputState, inputSymbol]] of this.nfa.transitions) {
      if (inputSymbol === symbol) {
        this.nfa.transitions.delete([inputState, inputSymbol]);
      }
    }
    this.hook.setTransitions(Array.from(this.nfa.transitions));
  }

  /**
   * Adds a transition to the NFA
   * @param param0 The NFATransition
   * @returns Nothing if the NFA transition is not valid
   */
  public addTransition([[inputState, inputSymbol], outputState]: [
    NFATransitionInput,
    NFATransitionOutput,
  ]): void {
    if (!this.nfa.states.has(inputState)) return;
    if (!this.nfa.inputSymbols.has(inputSymbol)) return;
    if (!this.nfa.states.has(outputState)) return;
    const outputStates = new Set(
      this.nfa.transitions.get([inputState, inputSymbol]),
    );
    outputStates.add(outputState);
    this.nfa.transitions.set(
      [inputState, inputSymbol],
      Array.from(outputStates),
    );
    this.hook.setTransitions(Array.from(this.nfa.transitions));
  }

  /**
   * Deletes a transition in the NFA
   * @param param0 The NFATransitionInput
   */
  public deleteTransition([input, outputState]: [
    NFATransitionInput,
    NFATransitionOutput,
  ]): void {
    const outputStates = new Set(this.nfa.transitions.get(input));
    outputStates.delete(outputState);
    if (outputStates.size === 0) {
      this.nfa.transitions.delete(input);
    } else {
      this.nfa.transitions.set(input, Array.from(outputStates));
    }
    this.hook.setTransitions(Array.from(this.nfa.transitions));
  }

  /**
   * Modifies a transition symbol in an NFA transition
   * @param param0 The NFATransition
   * @param newInputSymbol A new input symbol
   * @returns Nothing if the new input symbol is invalid
   */
  public modifyTransitionSymbol(
    [[inputState, inputSymbol], outputState]: [
      NFATransitionInput,
      NFATransitionOutput,
    ],
    newInputSymbol: NFAInputSymbol,
  ): void {
    if (!this.nfa.inputSymbols.has(newInputSymbol)) return;
    this.deleteTransition([[inputState, inputSymbol], outputState]);
    this.addTransition([[inputState, newInputSymbol], outputState]);
  }

  /**
   * Modifies a transition output in an NFA transition
   * @param param0 The NFATransitionInput
   * @param outputStates A new NFATransitionOutput
   * @returns Nothing if the new NFATransitionOutput is invalid
   */
  public modifyTransitionOutput(
    [[inputState, inputSymbol]]: [NFATransitionInput],
    outputStates: readonly NFATransitionOutput[],
  ): void {
    if (outputStates.some((outputState) => !this.nfa.states.has(outputState)))
      return;
    this.nfa.transitions.set(
      [inputState, inputSymbol],
      Array.from(new Set(outputStates)),
    );
    this.hook.setTransitions(Array.from(this.nfa.transitions));
  }

  /**
   * Adds an initial state to the NFA
   * @param state An NFA state
   * @returns Nothing if the DFA state doesn't exist
   */
  public addInitialState(state: NFAState): void {
    if (!this.nfa.states.has(state)) return;
    this.nfa.initialStates.add(state);
    this.hook.setInitialStates(Array.from(this.nfa.initialStates));
  }

  /**
   * Deletes an initial state in the NFA
   * @param state An NFA state
   */
  public deleteInitialState(state: NFAState): void {
    this.nfa.initialStates.delete(state);
    this.hook.setInitialStates(Array.from(this.nfa.initialStates));
  }

  /**
   * Adds a final state to the NFA
   * @param state A NFA state
   * @returns Nothing if the NFA state doesn't exist
   */
  public addFinalState(state: NFAState): void {
    if (!this.nfa.states.has(state)) return;
    this.nfa.finalStates.add(state);
    this.hook.setFinalStates(Array.from(this.nfa.finalStates));
  }

  /**
   * Deletes a final state in the NFA
   * @param state A NFA state
   */
  public deleteFinalState(state: NFAState): void {
    this.nfa.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.nfa.finalStates));
  }

  /**
   * Gets the position of a NFA State
   * @param state A NFA state
   * @returns The position of the given DFA state
   */
  public getPosition(state: NFAState): PointXY {
    return this.ptnfa.get(state);
  }

  /**
   * Sets the position of the given NFA state
   * @param state A NFA state
   * @param position The position of the NFA state
   * @returns Nothing if the NFA state doesn't exist
   */
  public setPosition(state: NFAState, position: PointXY): void {
    if (!this.ptnfa.has(state)) return;
    this.ptnfa.set(state, position);
    this.hookPNFA(Array.from(this.ptnfa));
  }
}

/**
 * Hook that returns a ReactiveNFA instance from the given SerialNFA and previous PNFA
 * @returns A tuple containing the SerialNFA, ReactiveNFA, and PositionsNFA instances
 */
export function useReactiveNFA(): [SerialNFA, ReactiveNFA, PositionsNFA] {
  const [states, setStates] = useState<NFAState[]>([]);
  const [inputSymbols, setInputSymbols] = useState<NFAInputSymbol[]>([]);
  const [transitions, setTransitions] = useState<
    [NFATransitionInput, NFATransitionOutput[]][]
  >([]);
  const [initialStates, setInitialStates] = useState<NFAState[]>([]);
  const [finalStates, setFinalStates] = useState<NFAState[]>([]);
  const [pnfa, setPNFA] = useState<[NFAState, PointXY][]>([]);

  /**
   * The SerialNFA instance
   */
  const snfa: SerialNFA = {
    states,
    inputSymbols,
    transitions,
    initialStates,
    finalStates,
  };

  /**
   * The HookSerialNFA instance
   */
  const hook: HookSerialNFA = {
    setStates,
    setInputSymbols,
    setTransitions,
    setInitialStates,
    setFinalStates,
  };

  /**
   * The NFA instance
   */
  const nfa: NFA = {
    states: new Set(states),
    inputSymbols: new Set(inputSymbols),
    transitions: new ObjectMap(transitions),
    initialStates: new Set(initialStates),
    finalStates: new Set(finalStates),
  };

  /**
   * The PositionTableNFA instance
   */
  const ptnfa: PositionTableNFA = new ObjectMap(pnfa);

  /**
   * The ReactiveNFA instance
   */
  const rnfa: ReactiveNFA = new ReactiveNFA(nfa, hook, ptnfa, setPNFA);

  return [snfa, rnfa, pnfa];
}
