import { useContext, useEffect, useRef, useState } from "react";
import { AutomatonState } from "../../components/AutomatonState";

import {
  PDAInputSymbol,
  PDAStackSymbol,
  PDAState,
  PositionsPDA,
  ReactivePDA,
  SerialPDA,
} from "../../../lib/ReactivePDA";
import {
  Box,
  Button,
  Code,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useReactiveJSPlumb } from "../../../lib/jsplumb/ReactiveJSPlumb";
import { Connection, EVENT_CONNECTION, LabelOverlay } from "@jsplumb/core";
import { EVENT_CONNECTION_DBL_CLICK } from "@jsplumb/browser-ui";
import { FramePDA } from "../../../lib/AnimationPDA";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
 * A component containing the modal to edit the transition
 * @param param0
 * @returns A JSX component containing the modal to edit the transition
 */
function EditTransitionModal({
  rpda,
  openModalRef,
  closeModalRef,
}: {
  rpda: ReactivePDA;
  openModalRef: React.MutableRefObject<(conn: Connection) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputSymbol, setInputSymbol] = useState<PDAInputSymbol>("");
  const [inputStackSymbol, setInputStackSymbol] = useState<PDAStackSymbol>("");
  const [outputStackString, setOutputStackString] = useState<string>("");
  const [connection, setConnection] = useState<Connection>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    const data = (connection?.overlays?.data as LabelOverlay)?.labelText || "";
    if (data) {
      try {
        const [
          [oldInputState, oldInputSymbol, oldInputStackSymbol],
          [oldOutputState, oldOutputStackSymbols],
        ] = JSON.parse(data);
        rpda.modifyTransitionSymbol(
          [
            [oldInputState, oldInputSymbol, oldInputStackSymbol],
            [oldOutputState, oldOutputStackSymbols],
          ],
          inputSymbol,
          inputStackSymbol,
          JSON.parse(outputStackString),
        )
      } catch (e) {
        toast({
          title: 'Unable to parse the transition as JSON.',
          status: 'error',
          isClosable: true,
        });
      }
    } else {
      rpda.addTransition([
        [connection.sourceId, inputSymbol, inputStackSymbol],
        [connection.targetId, JSON.parse(outputStackString)],
      ]);
    }
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (conn: Connection) => {
      const data = (conn?.overlays?.data as LabelOverlay)?.labelText || "";
      if (data) {
        const [
          [, oldInputSymbol, oldInputStackSymbol],
          [, oldOutputStackSymbols],
        ] = JSON.parse(data);
        setInputSymbol(oldInputSymbol);
        setInputStackSymbol(oldInputStackSymbol);
        setOutputStackString(JSON.stringify(oldOutputStackSymbols));
      }
      setConnection(conn);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Transition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}>
            <FormLabel>Source</FormLabel>
            <Input value={connection?.sourceId} disabled />
            <FormLabel>Target</FormLabel>
            <Input value={connection?.targetId} disabled />
            <FormLabel>Input Symbol</FormLabel>
            <Input
              value={inputSymbol}
              onChange={(e) => setInputSymbol(e.target.value)}
            />
            <FormLabel>Input Stack Symbol</FormLabel>
            <Input
              value={inputStackSymbol}
              onChange={(e) => setInputStackSymbol(e.target.value)}
            />
            <FormLabel>Output Stack String</FormLabel>
            <Input
              value={outputStackString}
              onChange={(e) => setOutputStackString(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the modal to edit the state
 * @param param0
 * @returns A JSX component containing the modal to edit the state
 */
function EditStateModal({
  rpda,
  openModalRef,
  closeModalRef,
}: {
  rpda: ReactivePDA;
  openModalRef: React.MutableRefObject<(state: PDAState) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<PDAState>("");
  const [state, setState] = useState<PDAState>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    rpda.renameState(state, input);
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (state: PDAState) => {
      setInput(state);
      setState(state);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit State</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the PDA visually
 * @param param0 The SerialPDA, ReactivePDA, PositionsPDA and FramePDA instances
 * @returns A JSX component containing the PDA visually
 */
export function VisualPDA({
  spda,
  rpda,
  ppda,
  fpda,
}: {
  spda: SerialPDA;
  rpda: ReactivePDA;
  ppda: PositionsPDA;
  fpda: FramePDA;
}) {
  const isActivated = useContext(ActivationContext);

  const containerRef = useRef<HTMLDivElement>();
  const jsplumb = useReactiveJSPlumb(containerRef);

  const openEditTransitionModalRef = useRef<(conn: Connection) => void>(
    () => {},
  );
  const closeEditTransitionModalRef = useRef<() => void>(() => {});

  const openEditStateModalRef = useRef<(state: PDAState) => void>(() => {});
  const closeEditStateModalRef = useRef<() => void>(() => {});

  const dblClickRenameState = (state: PDAState) => {
    if (isActivated) return;
    openEditStateModalRef.current(state);
  };

  const dblClickAddState = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isActivated) return;
    if (e.target === containerRef.current) {
      let number = 0;
      while (spda.states.includes(`q${number}`)) number++;
      const state = `q${number}`;
      const rect = containerRef.current.getBoundingClientRect();
      rpda.addState(state);
      rpda.setPosition(state, [
        e.clientX - rect.x - 48,
        e.clientY - rect.y - 24,
      ]);
    }
  };

  useEffect(() => {
    if (!jsplumb) return;
    jsplumb.setZoom(1);
    jsplumb.unbind(EVENT_CONNECTION);
    jsplumb.bind(EVENT_CONNECTION, (payload, e) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {
        jsplumb.deleteConnection(payload.connection);
      };
      if (e) openEditTransitionModalRef.current(payload.connection);
    });
    jsplumb.unbind(EVENT_CONNECTION_DBL_CLICK);
    jsplumb.bind(EVENT_CONNECTION_DBL_CLICK, (connection) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {};
      openEditTransitionModalRef.current(connection);
    });
  }, [jsplumb, isActivated]);

  useEffect(() => {
    jsplumb?.batch(() => {
      jsplumb.deleteEveryConnection();
      spda.transitions.forEach(
        ([[inputState, inputSymbol, inputStackSymbol], output]) => {
          output.forEach(([outputState, outputStackSymbols]) => {
            jsplumb.connect({
              source: jsplumb.getManagedElement(inputState),
              target: jsplumb.getManagedElement(outputState),
              overlays: [
                {
                  type: LabelOverlay.type,
                  options: {
                    location: 0.5,
                    label: `${
                      // TODO: redundant
                      inputSymbol === "" ? "ε" : inputSymbol
                    }, ${inputStackSymbol === "" ? "ε" : inputStackSymbol}, ${
                      outputStackSymbols.join("") === ""
                        ? "ε"
                        : outputStackSymbols.join("")
                    }`,
                  },
                },
                {
                  type: LabelOverlay.type,
                  options: {
                    location: 0.5,
                    label: JSON.stringify([
                      [inputState, inputSymbol, inputStackSymbol],
                      [outputState, outputStackSymbols],
                    ]),
                    id: "data",
                    cssClass: "jtk-hidden",
                  },
                },
                ...(!isActivated
                  ? [
                      {
                        type: LabelOverlay.type,
                        options: {
                          location: 0,
                          label: "×",
                          events: {
                            click: () => {
                              rpda.deleteTransition([
                                [inputState, inputSymbol, inputStackSymbol],
                                [outputState, outputStackSymbols],
                              ]);
                            },
                          },
                        },
                      },
                    ]
                  : []),
              ],
            });
          });
        },
      );
    });
  }, [jsplumb, spda, rpda, isActivated]);

  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        ref={containerRef}
        onDoubleClick={dblClickAddState}
      >
        {spda.states.map((state) => {
          const position = new Map(ppda).get(state) || [0, 0];
          const tokens = new Map(fpda).get(state) || [];
          return (
            <AutomatonState
              key={state}
              state={state}
              position={position}
              isInitialState={spda.initialState === state}
              isFinalState={spda.finalStates.includes(state)}
              // TODO: change this
              hasTokens={tokens.length > 0}
              hasEmptyToken={tokens.some(([token]) => token.length === 0)}
              jsplumb={jsplumb}
              onDoubleClick={() => dblClickRenameState(state)}
              onDelete={() => rpda.deleteState(state)}
              updatePosition={(pos) => {
                rpda.setPosition(state, pos);
              }}
            >
              <Stack>
                {tokens.map(([token, stack]) => (
                  <Code key={[token, stack].toString()}>
                    &nbsp;{token === "" ? "ε" : token},{" "}
                    {stack.toString() === "" ? "ε" : stack.toString()}&nbsp;
                  </Code>
                ))}
              </Stack>
            </AutomatonState>
          );
        })}
      </Box>
      <EditTransitionModal
        rpda={rpda}
        openModalRef={openEditTransitionModalRef}
        closeModalRef={closeEditTransitionModalRef}
      />
      <EditStateModal
        rpda={rpda}
        openModalRef={openEditStateModalRef}
        closeModalRef={closeEditStateModalRef}
      />
    </>
  );
}
