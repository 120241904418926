import { useState } from "react";

import { ObjectMap } from "./util/ObjectMap";
import { PointXY } from "./util/PointXY";
import { AutomatonType, DataDFA } from "./util/AutomatonType";

/**
 * A deterministic finite automata (DFA) state
 */
export type DFAState = string;
/**
 * A symbol in the DFA's input alphabet
 */
export type DFAInputSymbol = string;
/**
 * A DFA transition input: a state and an input symbol
 */
export type DFATransitionInput = readonly [DFAState, DFAInputSymbol];
/**
 * A DFA transition output: a state
 */
export type DFATransitionOutput = DFAState;
/**
 * A unique identifier for a DFA, consisting of an initial state and an array of input symbols
 */
export type DFAID = readonly [DFAState, readonly DFAInputSymbol[]];

/**
 * Defination of DFA
 */
export type DFA = {
  /**
   * A finite set of states Q
   */
  readonly states: Set<DFAState>;
  /**
   * A finite set of input symbols, the alphabet, Σ
   */
  readonly inputSymbols: Set<DFAInputSymbol>;
  /**
   * A transition function δ ∈ Q × Σ → Q
   */
  readonly transitions: ObjectMap<DFATransitionInput, DFATransitionOutput>;
  /**
   * An initial state q0 ∈ Q
   */
  initialState?: DFAState;
  /**
   * A set of final states F ⊆ Q
   */
  readonly finalStates: Set<DFAState>;
};

/**
 * Defination of SerialDFA
 */
export type SerialDFA = {
  /**
   * The array of states in the DFA
   */
  readonly states: readonly DFAState[];
  /**
   * The array of input symbols in the DFA's alphabet
   */
  readonly inputSymbols: readonly DFAInputSymbol[];
  /**
   * The array of transitions in the DFA, where each transition is represented
   * as a tuple of a DFATransitionInput and a DFATransitionOutput
   */
  readonly transitions: readonly [DFATransitionInput, DFATransitionOutput][];
  /**
   * The initial state of the DFA
   */
  readonly initialState?: DFAState;
  /**
   * The array of final states in the DFA
   */
  readonly finalStates: readonly DFAState[];
};

/**
 * Hook that can be used to manipulate SerialDFA
 */
export type HookSerialDFA = {
  readonly setStates: React.Dispatch<React.SetStateAction<readonly DFAState[]>>;
  readonly setInputSymbols: React.Dispatch<
    React.SetStateAction<readonly DFAInputSymbol[]>
  >;
  readonly setTransitions: React.Dispatch<
    React.SetStateAction<readonly [DFATransitionInput, DFATransitionOutput][]>
  >;
  readonly setInitialState: React.Dispatch<React.SetStateAction<DFAState>>;
  readonly setFinalStates: React.Dispatch<
    React.SetStateAction<readonly DFAState[]>
  >;
};

/**
 * A mapping from DFAState to PointXY
 */
export type PositionTableDFA = ObjectMap<DFAState, PointXY>;
/**
 * An array of tuples, where each tuple contains a DFAState and its corresponding PointXY position
 */
export type PositionsDFA = readonly (readonly [DFAState, PointXY])[];
/**
 * Hook of PositionsDFA
 */
export type HookPositionsDFA = React.Dispatch<
  React.SetStateAction<PositionsDFA>
>;

/**
 * The defination of ReactiveDFA
 */
export class ReactiveDFA {
  /**
   * A deterministic finite automata (DFA)
   */
  private dfa: DFA;
  /**
   * A hook of SerialDFA
   */
  private readonly hook: HookSerialDFA;
  /**
   * A Position Table of DFA
   */
  private ptdfa: PositionTableDFA;
  /**
   * A hook of positions of DFA
   */
  private readonly hookPDFA: HookPositionsDFA;

  /**
   * Constructs a ReactiveDFA
   * @param dfa A deterministic finite automata (DFA)
   * @param hook A hook of SerialDFA
   * @param ptdfa A Position Table of DFA
   * @param hookPDFA A hook of positions of DFA
   */
  public constructor(
    dfa: DFA,
    hook: HookSerialDFA,
    ptdfa: PositionTableDFA,
    hookPDFA: HookPositionsDFA,
  ) {
    this.dfa = dfa;
    this.hook = hook;
    this.ptdfa = ptdfa;
    this.hookPDFA = hookPDFA;
  }

  /**
   * Imports data to the DFA
   * @param param0 The Data to be imported
   */
  public importData(
    {
      automaton: {
        states,
        inputSymbols,
        transitions,
        initialState,
        finalStates,
      },
      positions,
    }: DataDFA = {
      type: AutomatonType.DFA,
      automaton: {
        states: [],
        inputSymbols: [],
        transitions: [],
        finalStates: [],
      },
      positions: [],
    },
  ): void {
    this.dfa = {
      states: new Set(states),
      inputSymbols: new Set(inputSymbols),
      transitions: new ObjectMap(transitions),
      initialState: initialState,
      finalStates: new Set(finalStates),
    };
    this.ptdfa = new ObjectMap(positions);

    this.hook.setStates(Array.from(this.dfa.states));
    this.hook.setInputSymbols(Array.from(this.dfa.inputSymbols));
    this.hook.setTransitions(Array.from(this.dfa.transitions));
    this.hook.setInitialState(this.dfa.initialState);
    this.hook.setFinalStates(Array.from(this.dfa.finalStates));
    this.hookPDFA(Array.from(this.ptdfa));
  }

  /**
   * Adds a state to the DFA
   * @param state A DFA state
   */
  public addState(state: DFAState): void {
    this.dfa.states.add(state);
    this.hook.setStates(Array.from(this.dfa.states));

    this.ptdfa.set(state, [0, 0]);
    this.hookPDFA(Array.from(this.ptdfa));
  }

  /**
   * Deletes a state in the DFA
   * @param state A DFA state
   */
  public deleteState(state: DFAState): void {
    this.dfa.states.delete(state);
    this.hook.setStates(Array.from(this.dfa.states));

    for (const [[inputState, inputSymbol], outputState] of this.dfa
      .transitions) {
      if (inputState === state || outputState === state) {
        this.dfa.transitions.delete([inputState, inputSymbol]);
      }
    }
    this.hook.setTransitions(Array.from(this.dfa.transitions));

    if (this.dfa.initialState === state) delete this.dfa.initialState;
    this.hook.setInitialState(this.dfa.initialState);

    this.dfa.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.dfa.finalStates));

    this.ptdfa.delete(state);
    this.hookPDFA(Array.from(this.ptdfa));
  }

  /**
   * Renames a state in the DFA
   * @param oldState The old DFA state
   * @param newState The new DFA state
   * @returns Nothing if the new DFA state is already existed
   */
  public renameState(oldState: DFAState, newState: DFAState): void {
    if (this.dfa.states.has(newState)) return;
    // create a new state
    this.addState(newState);
    this.setPosition(newState, this.ptdfa.get(oldState));
    if (this.dfa.initialState === oldState) this.setInitialState(newState);
    if (this.dfa.finalStates.has(oldState)) this.addFinalState(newState);
    // migrate transitions from and to the state
    for (const [[oldInputState, inputSymbol], oldOutputState] of this.dfa
      .transitions) {
      const isInput = oldInputState === oldState;
      const isOutput = oldOutputState === oldState;
      if (isInput || isOutput) {
        const newInputState = isInput ? newState : oldInputState;
        const newOutputState = isOutput ? newState : oldOutputState;
        this.deleteTransition([[oldInputState, inputSymbol]]);
        this.addTransition([[newInputState, inputSymbol], newOutputState]);
      }
    }
    // delete the old state
    this.deleteState(oldState);
  }

  /**
   * Adds an input symbol to the DFA
   * @param symbol A DFA input symbol
   */
  public addInputSymbol(symbol: DFAInputSymbol): void {
    this.dfa.inputSymbols.add(symbol);
    this.hook.setInputSymbols(Array.from(this.dfa.inputSymbols));
  }

  /**
   * Deletes an input symbol in the DFA
   * @param symbol A DFA input symbol
   */
  public deleteInputSymbol(symbol: DFAInputSymbol): void {
    this.dfa.inputSymbols.delete(symbol);
    this.hook.setInputSymbols(Array.from(this.dfa.inputSymbols));

    for (const [[inputState, inputSymbol]] of this.dfa.transitions) {
      if (inputSymbol === symbol) {
        this.dfa.transitions.delete([inputState, inputSymbol]);
      }
    }
    this.hook.setTransitions(Array.from(this.dfa.transitions));
  }

  /**
   * Adds a transition to the DFA
   * @param param0 The DFATransition
   * @returns Nothing if the DFA transition is not valid
   */
  public addTransition([[inputState, inputSymbol], outputState]: [
    DFATransitionInput,
    DFATransitionOutput,
  ]): void {
    if (!this.dfa.states.has(inputState)) return;
    if (!this.dfa.inputSymbols.has(inputSymbol)) return;
    if (!this.dfa.states.has(outputState)) return;
    this.dfa.transitions.set([inputState, inputSymbol], outputState);
    this.hook.setTransitions(Array.from(this.dfa.transitions));
  }

  /**
   * Deletes a transition in the DFA
   * @param param0 The DFATransitionInput
   */
  public deleteTransition([input]: [DFATransitionInput]): void {
    this.dfa.transitions.delete(input);
    this.hook.setTransitions(Array.from(this.dfa.transitions));
  }

  /**
   * Modifies a transition symbol in a DFA transition
   * @param param0 The DFATransition
   * @param newInputSymbol A new input symbol
   * @returns Nothing if the new input symbol is invalid
   */
  public modifyTransitionSymbol(
    [[inputState, inputSymbol], outputState]: [
      DFATransitionInput,
      DFATransitionOutput,
    ],
    newInputSymbol: DFAInputSymbol,
  ): void {
    if (!this.dfa.inputSymbols.has(newInputSymbol)) return;
    this.deleteTransition([[inputState, inputSymbol]]);
    this.addTransition([[inputState, newInputSymbol], outputState]);
  }

  /**
   * Modifies a transition output in a DFA transition
   * @param param0 The DFATransitionInput
   * @param newOutputState A new DFATransitionOutput
   * @returns Nothing if the new DFATransitionOutput is invalid
   */
  public modifyTransitionOutput(
    [[inputState, inputSymbol]]: [DFATransitionInput],
    newOutputState: DFATransitionOutput,
  ): void {
    if (!this.dfa.states.has(newOutputState)) return;
    this.deleteTransition([[inputState, inputSymbol]]);
    this.addTransition([[inputState, inputSymbol], newOutputState]);
  }

  /**
   * Sets an initial state to the DFA
   * @param state A DFA state
   * @returns Nothing if the DFA state doesn't exist
   */
  public setInitialState(state: DFAState): void {
    if (!this.dfa.states.has(state)) return;
    this.dfa.initialState = state;
    this.hook.setInitialState(this.dfa.initialState);
  }

  /**
   * Adds a final state to the DFA
   * @param state A DFA state
   * @returns Nothing if the DFA state doesn't exist
   */
  public addFinalState(state: DFAState): void {
    if (!this.dfa.states.has(state)) return;
    this.dfa.finalStates.add(state);
    this.hook.setFinalStates(Array.from(this.dfa.finalStates));
  }

  /**
   * Deletes a final state in the DFA
   * @param state A DFA state
   */
  public deleteFinalState(state: DFAState): void {
    this.dfa.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.dfa.finalStates));
  }

  /**
   * Gets the position of a DFA state
   * @param state A DFA state
   * @returns The position of the given DFA state
   */
  public getPosition(state: DFAState): PointXY {
    return this.ptdfa.get(state);
  }

  /**
   * Sets the position of the given DFA state
   * @param state A DFA state
   * @param position The position of the DFA state
   * @returns Nothing if the DFA state doesn't exist
   */
  public setPosition(state: DFAState, position: PointXY): void {
    if (!this.ptdfa.has(state)) return;
    this.ptdfa.set(state, position);
    this.hookPDFA(Array.from(this.ptdfa));
  }
}

/**
 * Hook that returns a ReactiveDFA instance from the given SerialDFA and previous PDFA.
 * @returns A tuple containing the SerialDFA, ReactiveDFA, and PositionsDFA instances
 */
export function useReactiveDFA(): [SerialDFA, ReactiveDFA, PositionsDFA] {
  const [states, setStates] = useState<DFAState[]>([]);
  const [inputSymbols, setInputSymbols] = useState<DFAInputSymbol[]>([]);
  const [transitions, setTransitions] = useState<
    [DFATransitionInput, DFATransitionOutput][]
  >([]);
  const [initialState, setInitialState] = useState<DFAState>();
  const [finalStates, setFinalStates] = useState<DFAState[]>([]);
  const [pdfa, setPDFA] = useState<[DFAState, PointXY][]>([]);

  /**
   * The SerialDFA instance
   */
  const sdfa: SerialDFA = {
    states,
    inputSymbols,
    transitions,
    initialState,
    finalStates,
  };

  /**
   * The HookSerialDFA instance
   */
  const hook: HookSerialDFA = {
    setStates,
    setInputSymbols,
    setTransitions,
    setInitialState,
    setFinalStates,
  };

  /**
   * The DFA instance
   */
  const dfa: DFA = {
    states: new Set(states),
    inputSymbols: new Set(inputSymbols),
    transitions: new ObjectMap(transitions),
    initialState: initialState,
    finalStates: new Set(finalStates),
  };

  /**
   * The PositionTableDFA instance
   */
  const ptdfa: PositionTableDFA = new ObjectMap(pdfa);

  /**
   * The ReactiveDFA instance
   */
  const rdfa: ReactiveDFA = new ReactiveDFA(dfa, hook, ptdfa, setPDFA);

  return [sdfa, rdfa, pdfa];
}
