import { useContext, useEffect, useRef, useState } from "react";
import { AutomatonState } from "../../components/AutomatonState";

import {
  TMTapeSymbol,
  TMState,
  PositionsTM,
  ReactiveTM,
  SerialTM,
  TMDirection,
} from "../../../lib/ReactiveTM";
import {
  Box,
  Button,
  Code,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useReactiveJSPlumb } from "../../../lib/jsplumb/ReactiveJSPlumb";
import { Connection, EVENT_CONNECTION, LabelOverlay } from "@jsplumb/core";
import { EVENT_CONNECTION_DBL_CLICK } from "@jsplumb/browser-ui";
import { FrameTM } from "../../../lib/AnimationTM";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
 * A component containing the modal to edit the transition
 * @param param0
 * @returns A JSX component containing the modal to edit the transition
 */
function EditTransitionModal({
  rtm,
  openModalRef,
  closeModalRef,
}: {
  rtm: ReactiveTM;
  openModalRef: React.MutableRefObject<(conn: Connection) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputSymbol, setInputSymbol] = useState<TMTapeSymbol>("");
  const [outputSymbol, setOutputSymbol] = useState<TMTapeSymbol>("");
  const [outputDirection, setOutputDirection] = useState<TMDirection>(
    TMDirection.RIGHT,
  );
  const [connection, setConnection] = useState<Connection>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    const data = (connection?.overlays?.data as LabelOverlay)?.labelText || "";
    if (data) {
      const [
        [oldInputState, oldInputSymbol],
        [oldOutputState, oldOutputSymbol, oldOutputDirection],
      ] = JSON.parse(data);
      rtm.modifyTransition(
        [
          [oldInputState, oldInputSymbol],
          [oldOutputState, oldOutputSymbol, oldOutputDirection],
        ],
        inputSymbol,
        outputSymbol,
        outputDirection,
      );
    } else {
      rtm.addTransition([
        [connection.sourceId, inputSymbol],
        [connection.targetId, outputSymbol, outputDirection],
      ]);
    }
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (conn: Connection) => {
      const data = (conn?.overlays?.data as LabelOverlay)?.labelText || "";
      if (data) {
        const [[, oldInputSymbol], [, oldOutputSymbol, oldOutputDirection]] =
          JSON.parse(data);
        setInputSymbol(oldInputSymbol);
        setOutputSymbol(oldOutputSymbol);
        setOutputDirection(oldOutputDirection);
      }
      setConnection(conn);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Transition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Source</FormLabel>
            <Input value={connection?.sourceId} disabled />
            <FormLabel>Target</FormLabel>
            <Input value={connection?.targetId} disabled />
            <FormLabel>Input Symbol</FormLabel>
            <Input
              value={inputSymbol}
              onChange={(e) => setInputSymbol(e.target.value)}
            />
            <FormLabel>Output Symbol</FormLabel>
            <Input
              value={outputSymbol}
              onChange={(e) => setOutputSymbol(e.target.value)}
            />
            <FormLabel>Output Direction</FormLabel>
            <Select
              value={outputDirection}
              onChange={(e) =>
                setOutputDirection(e.target.value as TMDirection)
              }
            >
              <option value={TMDirection.LEFT}>LEFT</option>
              <option value={TMDirection.RIGHT}>RIGHT</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the modal to edit the state
 * @param param0
 * @returns A JSX component containing the modal to edit the state
 */
function EditStateModal({
  rtm,
  openModalRef,
  closeModalRef,
}: {
  rtm: ReactiveTM;
  openModalRef: React.MutableRefObject<(state: TMState) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<TMState>("");
  const [state, setState] = useState<TMState>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    rtm.renameState(state, input);
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (state: TMState) => {
      setInput(state);
      setState(state);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit State</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the TM visually
 * @param param0 The SerialTM, ReactiveTM, PositionsTM and FrameTM instances
 * @returns A JSX component containing the TM visually
 */
export function VisualTM({
  stm,
  rtm,
  ptm,
  ftm,
}: {
  stm: SerialTM;
  rtm: ReactiveTM;
  ptm: PositionsTM;
  ftm: FrameTM;
}) {
  const isActivated = useContext(ActivationContext);

  const containerRef = useRef<HTMLDivElement>();
  const jsplumb = useReactiveJSPlumb(containerRef);

  const openEditTransitionModalRef = useRef<(conn: Connection) => void>(
    () => {},
  );
  const closeEditTransitionModalRef = useRef<() => void>(() => {});

  const openEditStateModalRef = useRef<(state: TMState) => void>(() => {});
  const closeEditStateModalRef = useRef<() => void>(() => {});

  const dblClickRenameState = (state: TMState) => {
    if (isActivated) return;
    openEditStateModalRef.current(state);
  };

  const dblClickAddState = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isActivated) return;
    if (e.target === containerRef.current) {
      let number = 0;
      while (stm.states.includes(`q${number}`)) number++;
      const state = `q${number}`;
      const rect = containerRef.current.getBoundingClientRect();
      rtm.addState(state);
      rtm.setPosition(state, [
        e.clientX - rect.x - 48,
        e.clientY - rect.y - 24,
      ]);
    }
  };

  useEffect(() => {
    if (!jsplumb) return;
    jsplumb.setZoom(1);
    jsplumb.unbind(EVENT_CONNECTION);
    jsplumb.bind(EVENT_CONNECTION, (payload, e) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {
        jsplumb.deleteConnection(payload.connection);
      };
      if (e) openEditTransitionModalRef.current(payload.connection);
    });
    jsplumb.unbind(EVENT_CONNECTION_DBL_CLICK);
    jsplumb.bind(EVENT_CONNECTION_DBL_CLICK, (connection) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {};
      openEditTransitionModalRef.current(connection);
    });
  }, [jsplumb, isActivated]);

  useEffect(() => {
    jsplumb?.batch(() => {
      jsplumb.deleteEveryConnection();
      stm.transitions.forEach(([[inputState, inputSymbol], output]) => {
        output.forEach(([outputState, outputSymbol, outputDirection]) => {
          jsplumb.connect({
            source: jsplumb.getManagedElement(inputState),
            target: jsplumb.getManagedElement(outputState),
            overlays: [
              {
                type: LabelOverlay.type,
                options: {
                  location: 0.5,
                  label: `${
                    // TODO: redundant
                    inputSymbol === "" ? "ε" : inputSymbol
                  }, ${
                    outputSymbol === "" ? "ε" : outputSymbol
                  }, ${outputDirection}`,
                },
              },
              {
                type: LabelOverlay.type,
                options: {
                  location: 0.5,
                  label: JSON.stringify([
                    [inputState, inputSymbol],
                    [outputState, outputSymbol, outputDirection],
                  ]),
                  id: "data",
                  cssClass: "jtk-hidden",
                },
              },
              ...(!isActivated
                ? [
                    {
                      type: LabelOverlay.type,
                      options: {
                        location: 0,
                        label: "×",
                        events: {
                          click: () => {
                            rtm.deleteTransition([
                              [inputState, inputSymbol],
                              [outputState, outputSymbol, outputDirection],
                            ]);
                          },
                        },
                      },
                    },
                  ]
                : []),
            ],
          });
        });
      });
    });
  }, [jsplumb, stm, rtm, isActivated]);

  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        ref={containerRef}
        onDoubleClick={dblClickAddState}
      >
        {stm.states.map((state) => {
          const position = new Map(ptm).get(state) || [0, 0];
          const tokens = new Map(ftm).get(state) || [];
          return (
            <AutomatonState
              key={state}
              state={state}
              position={position}
              isInitialState={stm.initialState === state}
              isFinalState={stm.finalStates.includes(state)}
              hasTokens={tokens.length > 0}
              hasEmptyToken={true}
              jsplumb={jsplumb}
              onDoubleClick={() => dblClickRenameState(state)}
              onDelete={() => rtm.deleteState(state)}
              updatePosition={(pos) => {
                rtm.setPosition(state, pos);
              }}
            >
              <Stack>
                {tokens.map(([left, right, direction]) => (
                  <Code key={[left, right, direction].toString()}>
                    &nbsp;{left === "" ? "ε" : left},{" "}
                    {right === "" ? "ε" : right}, {direction}&nbsp;
                  </Code>
                ))}
              </Stack>
            </AutomatonState>
          );
        })}
      </Box>
      <EditTransitionModal
        rtm={rtm}
        openModalRef={openEditTransitionModalRef}
        closeModalRef={closeEditTransitionModalRef}
      />
      <EditStateModal
        rtm={rtm}
        openModalRef={openEditStateModalRef}
        closeModalRef={closeEditStateModalRef}
      />
    </>
  );
}
