import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
import { theme } from './theme';
import { ASKit } from './ui/ASKit';

const container = document.getElementById('root');

if (container !== null) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <>
      <ChakraProvider theme={theme}>
        <ASKit />
      </ChakraProvider>
      <ColorModeScript />
    </>,
  );
}

serviceWorker.unregister();
