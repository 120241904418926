import { useState } from "react";

import { ObjectMap } from "./util/ObjectMap";
import { PointXY } from "./util/PointXY";
import { AutomatonType, DataPDA } from "./util/AutomatonType";

/**
 * A pushdown automata (PDA) state
 */
export type PDAState = string;
/**
 * An input symbol in the PDA's input alphabet
 */
export type PDAInputSymbol = string;
/**
 * A stack symbol in the PDA's stack alphabet
 */
export type PDAStackSymbol = string;
/**
 * A PDA transition input: a state, an input symbol and a stack symbol
 */
export type PDATransitionInput = readonly [
  PDAState,
  PDAInputSymbol,
  PDAStackSymbol,
];
/**
 * A PDA transition output: a state and an array of stack symbols
 */
export type PDATransitionOutput = readonly [
  PDAState,
  readonly PDAStackSymbol[],
];
/**
 * A unique identifier for a PDA, consisting of an initial state, an array of input symbols and an array of stack symbols
 */
export type PDAID = readonly [
  PDAState,
  readonly PDAInputSymbol[],
  readonly PDAStackSymbol[],
];

/**
 * Defination of PDA
 */
export type PDA = {
  /**
   * A finite set Q of states
   */
  readonly states: Set<PDAState>;
  /**
   * A finite set Σ of input symbols (the alphabet)
   */
  readonly inputSymbols: Set<PDAInputSymbol>;
  /**
   * A finite set Γ of stack symbols
   */
  readonly stackSymbols: Set<PDAStackSymbol>;
  /**
   * A transition function δ ∈ Q × (Σ ∪ {ε}) × Γ → P(Q × Γ∗)
   */
  readonly transitions: ObjectMap<
    PDATransitionInput,
    readonly PDATransitionOutput[]
  >;
  /**
   * An initial state q0 ∈ Q
   */
  initialState?: PDAState;
  /**
   * An initial stack symbol Z0 ∈ Γ
   */
  initialStackSymbol?: PDAStackSymbol;
  /**
   * A set of final states F ⊆ Q
   */
  readonly finalStates: Set<PDAState>;
};

/**
 * Defination of SerialPDA
 */
export type SerialPDA = {
  /**
   * The array of states in the PDA
   */
  readonly states: readonly PDAState[];
  /**
   * The array of input symbols in the PDA's alphabet
   */
  readonly inputSymbols: readonly PDAInputSymbol[];
  /**
   * The array of stack symbols in the PDA's alphabet
   */
  readonly stackSymbols: readonly PDAStackSymbol[];
  /**
   * The array of transitions in the PDA, where each transition is represented
   * as a tuple of a PDATransitionInput and an array of PDATransitionOutput
   */
  readonly transitions: readonly (readonly [
    PDATransitionInput,
    readonly PDATransitionOutput[],
  ])[];
  /**
   * The initial state of the PDA
   */
  readonly initialState?: PDAState;
  /**
   * The initial stack symbol of the PDA
   */
  readonly initialStackSymbol?: PDAStackSymbol;
  /**
   * The array of final states in the PDA
   */
  readonly finalStates: readonly PDAState[];
};

/**
 * Hook that can be used to manipulate SerialPDA
 */
export type HookSerialPDA = {
  readonly setStates: React.Dispatch<React.SetStateAction<readonly PDAState[]>>;
  readonly setInputSymbols: React.Dispatch<
    React.SetStateAction<readonly PDAInputSymbol[]>
  >;
  readonly setStackSymbols: React.Dispatch<
    React.SetStateAction<readonly PDAStackSymbol[]>
  >;
  readonly setTransitions: React.Dispatch<
    React.SetStateAction<
      readonly (readonly [PDATransitionInput, readonly PDATransitionOutput[]])[]
    >
  >;
  readonly setInitialState: React.Dispatch<React.SetStateAction<PDAState>>;
  readonly setInitialStackSymbol: React.Dispatch<
    React.SetStateAction<PDAStackSymbol>
  >;
  readonly setFinalStates: React.Dispatch<
    React.SetStateAction<readonly PDAState[]>
  >;
};

/**
 * A mapping from PDAState to PointXY
 */
export type PositionTablePDA = ObjectMap<PDAState, PointXY>;
/**
 * An array of tuples, where each tuple contains a PDAState and its corresponding PointXY
 */
export type PositionsPDA = readonly (readonly [PDAState, PointXY])[];
/**
 * Hook of PositionsPDA
 */
export type HookPositionsPDA = React.Dispatch<
  React.SetStateAction<PositionsPDA>
>;

/**
 * The defination of ReactivePDA
 */
export class ReactivePDA {
  /**
   * A pushdown automata (PDA)
   */
  private pda: PDA;
  /**
   * A hook of SerialPDA
   */
  private readonly hook: HookSerialPDA;
  /**
   * A Position Table of PDA
   */
  private ptpda: PositionTablePDA;
  /**
   * A hook of positions of PDA
   */
  private readonly hookPPDA: HookPositionsPDA;

  /**
   * Constructs a ReactivePDA
   * @param pda A pushdown automata (PDA)
   * @param hook A hook of SerialPDA
   * @param ptpda A Position Table of PDA
   * @param hookPPDA A hook of positions of PDA
   */
  public constructor(
    pda: PDA,
    hook: HookSerialPDA,
    ptpda: PositionTablePDA,
    hookPPDA: HookPositionsPDA,
  ) {
    this.pda = pda;
    this.hook = hook;
    this.ptpda = ptpda;
    this.hookPPDA = hookPPDA;
  }

  /**
   * Imports data to the PDA
   * @param param0 The Data to be imported
   */
  public importData(
    {
      automaton: {
        states,
        inputSymbols,
        stackSymbols,
        transitions,
        initialState,
        initialStackSymbol,
        finalStates,
      },
      positions,
    }: DataPDA = {
      type: AutomatonType.PDA,
      automaton: {
        states: [],
        inputSymbols: [],
        stackSymbols: [],
        transitions: [],
        finalStates: [],
      },
      positions: [],
    },
  ): void {
    this.pda = {
      states: new Set(states),
      inputSymbols: new Set(inputSymbols),
      stackSymbols: new Set(stackSymbols),
      transitions: new ObjectMap(transitions),
      initialState,
      initialStackSymbol,
      finalStates: new Set(finalStates),
    };
    this.ptpda = new ObjectMap(positions);

    this.hook.setStates(Array.from(this.pda.states));
    this.hook.setInputSymbols(Array.from(this.pda.inputSymbols));
    this.hook.setStackSymbols(Array.from(this.pda.stackSymbols));
    this.hook.setTransitions(Array.from(this.pda.transitions));
    this.hook.setInitialState(this.pda.initialState);
    this.hook.setInitialStackSymbol(this.pda.initialStackSymbol);
    this.hook.setFinalStates(Array.from(this.pda.finalStates));
    this.hookPPDA(Array.from(this.ptpda));
  }

  /**
   * Adds a state to the PDA
   * @param state A PDA state
   */
  public addState(state: PDAState): void {
    this.pda.states.add(state);
    this.hook.setStates(Array.from(this.pda.states));

    this.ptpda.set(state, [0, 0]);
    this.hookPPDA(Array.from(this.ptpda));
  }

  /**
   * Deletes a state in the PDA
   * @param state A PDA state
   */
  public deleteState(state: PDAState): void {
    this.pda.states.delete(state);
    this.hook.setStates(Array.from(this.pda.states));

    for (const [[inputState, inputSymbol, inputStackSymbol], output] of this.pda
      .transitions) {
      if (inputState === state) {
        this.pda.transitions.delete([
          inputState,
          inputSymbol,
          inputStackSymbol,
        ]);
      } else {
        const outputAfterDeletion = output.filter(
          ([outputState]) => outputState !== state,
        );
        this.pda.transitions.set(
          [inputState, inputSymbol, inputStackSymbol],
          outputAfterDeletion,
        );
      }
    }
    this.hook.setTransitions(Array.from(this.pda.transitions));

    if (this.pda.initialState === state) delete this.pda.initialState;
    this.hook.setInitialState(this.pda.initialState);

    this.pda.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.pda.finalStates));

    this.ptpda.delete(state);
    this.hookPPDA(Array.from(this.ptpda));
  }

  /**
   * Renames a state in the PDA
   * @param oldState The old PDA state
   * @param newState The new PDA state
   * @returns Nothing if the new PDA state is already existed
   */
  public renameState(oldState: PDAState, newState: PDAState): void {
    if (this.pda.states.has(newState)) return;
    // create a new state
    this.addState(newState);
    this.setPosition(newState, this.ptpda.get(oldState));
    if (this.pda.initialState === oldState) this.setInitialState(newState);
    if (this.pda.finalStates.has(oldState)) this.addFinalState(newState);
    // migrate transitions from and to the state
    for (const [
      [oldInputState, inputSymbol, inputStackSymbol],
      oldOutput,
    ] of this.pda.transitions) {
      const isInput = oldInputState === oldState;
      const isOutput = oldOutput.some(
        ([oldOutputState]) => oldOutputState === oldState,
      );
      if (isInput || isOutput) {
        const newInputState: PDAState = isInput ? newState : oldInputState;
        const newOutput: readonly PDATransitionOutput[] = oldOutput.map(
          ([outputState, outputStackSymbols]) => [
            outputState === oldState ? newState : outputState,
            outputStackSymbols,
          ],
        );
        for (const output of oldOutput) {
          this.deleteTransition([
            [oldInputState, inputSymbol, inputStackSymbol],
            output,
          ]);
        }
        for (const output of newOutput) {
          this.addTransition([
            [newInputState, inputSymbol, inputStackSymbol],
            output,
          ]);
        }
      }
    }
    // delete the old state
    this.deleteState(oldState);
  }

  /**
   * Adds an input symbol to the PDA
   * @param symbol A PDA input symbol
   */
  public addInputSymbol(symbol: PDAInputSymbol): void {
    this.pda.inputSymbols.add(symbol);
    this.hook.setInputSymbols(Array.from(this.pda.inputSymbols));
  }

  /**
   * Deletes an input symbol in the PDA
   * @param symbol A PDA input symbol
   */
  public deleteInputSymbol(symbol: PDAInputSymbol): void {
    this.pda.inputSymbols.delete(symbol);
    this.hook.setInputSymbols(Array.from(this.pda.inputSymbols));

    for (const [[inputState, inputSymbol, inputStackSymbol]] of this.pda
      .transitions) {
      if (inputSymbol === symbol) {
        this.pda.transitions.delete([
          inputState,
          inputSymbol,
          inputStackSymbol,
        ]);
      }
    }
    this.hook.setTransitions(Array.from(this.pda.transitions));
  }

  /**
   * Adds a stack symbol to the PDA
   * @param symbol A PDA stack symbol
   */
  public addStackSymbol(symbol: PDAStackSymbol): void {
    this.pda.stackSymbols.add(symbol);
    this.hook.setStackSymbols(Array.from(this.pda.stackSymbols));
  }

  /**
   * Deletes a stack symbol in the PDA
   * @param symbol A PDA stack symbol
   */
  public deleteStackSymbol(symbol: PDAStackSymbol): void {
    this.pda.stackSymbols.delete(symbol);
    this.hook.setStackSymbols(Array.from(this.pda.stackSymbols));

    for (const [[inputState, inputSymbol, inputStackSymbol], output] of this.pda
      .transitions) {
      if (inputStackSymbol === symbol) {
        this.pda.transitions.delete([
          inputState,
          inputSymbol,
          inputStackSymbol,
        ]);
      } else {
        const outputAfterDeletion = output.filter(
          ([, outputStackSymbols]) => !outputStackSymbols.includes(symbol),
        );
        this.pda.transitions.set(
          [inputState, inputSymbol, inputStackSymbol],
          outputAfterDeletion,
        );
      }
    }
    this.hook.setTransitions(Array.from(this.pda.transitions));

    if (this.pda.initialStackSymbol === symbol)
      delete this.pda.initialStackSymbol;
    this.hook.setInitialStackSymbol(this.pda.initialStackSymbol);
  }

  /**
   * Adds a transition to the PDA
   * @param param0 The PDATransition
   * @returns Nothing if the PFA transition is not valid
   */
  public addTransition([
    [inputState, inputSymbol, inputStackSymbol],
    [outputState, outputStackSymbols],
  ]: [PDATransitionInput, PDATransitionOutput]): void {
    if (!this.pda.states.has(inputState)) return;
    if (!this.pda.inputSymbols.has(inputSymbol)) return;
    if (!this.pda.stackSymbols.has(inputStackSymbol)) return;
    if (!this.pda.states.has(outputState)) return;
    if (
      outputStackSymbols.some(
        (outputStackSymbol) => !this.pda.stackSymbols.has(outputStackSymbol),
      )
    )
      return;

    const outputBeforeAddition =
      this.pda.transitions.get([inputState, inputSymbol, inputStackSymbol]) ||
      [];
    const outputInPDA = outputBeforeAddition.filter(
      ([elOutputState, elOutputStackSymbols]) =>
        elOutputState === outputState &&
        JSON.stringify(elOutputStackSymbols) ===
          JSON.stringify(outputStackSymbols),
    );
    if (outputInPDA.length > 0) return;
    this.pda.transitions.set(
      [inputState, inputSymbol, inputStackSymbol],
      outputBeforeAddition.concat([[outputState, outputStackSymbols]]),
    );
    this.hook.setTransitions(Array.from(this.pda.transitions));
  }

  /**
   * Deletes a transition in the PDA
   * @param param0 The PDATransition
   */
  public deleteTransition([input, [outputState, outputStackSymbols]]: [
    PDATransitionInput,
    PDATransitionOutput,
  ]): void {
    const outputBeforeDeletion = this.pda.transitions.get(input) || [];
    const outputAfterDeletion = outputBeforeDeletion.filter(
      ([elOutputState, elOutputStackSymbols]) =>
        !(
          elOutputState === outputState &&
          JSON.stringify(elOutputStackSymbols) ===
            JSON.stringify(outputStackSymbols)
        ),
    );
    this.pda.transitions.set(input, outputAfterDeletion);
    this.hook.setTransitions(Array.from(this.pda.transitions));
  }

  /**
   * Modifies a transition symbol in a PDA transition
   * @param param0 The PDATransition
   * @param newInputSymbol A new PDA transition input symbol
   * @param newInputStackSymbol A new PDA transition input stack symbol
   * @param newOutputStackSymbols A new PDA transition output stack symbol
   * @returns Nothing if the input symbol or input stack symbol is invalid
   */
  public modifyTransitionSymbol(
    [
      [inputState, inputSymbol, inputStackSymbol],
      [outputState, outputStackSymbols],
    ]: [PDATransitionInput, PDATransitionOutput],
    newInputSymbol: PDAInputSymbol,
    newInputStackSymbol: PDAStackSymbol,
    newOutputStackSymbols: readonly PDAStackSymbol[],
  ): void {
    if (!this.pda.inputSymbols.has(newInputSymbol)) return;
    if (!this.pda.stackSymbols.has(newInputStackSymbol)) return;
    if (
      newOutputStackSymbols.some(
        (outputStackSymbol) => !this.pda.stackSymbols.has(outputStackSymbol),
      )
    )
      return;
    this.deleteTransition([
      [inputState, inputSymbol, inputStackSymbol],
      [outputState, outputStackSymbols],
    ]);
    this.addTransition([
      [inputState, newInputSymbol, newInputStackSymbol],
      [outputState, newOutputStackSymbols],
    ]);
  }

  /**
   * Sets an initial state to the PDA
   * @param state A PDA state
   * @returns Nothing if the PDA state doesn't exist
   */
  public setInitialState(state: PDAState): void {
    if (!this.pda.states.has(state)) return;
    this.pda.initialState = state;
    this.hook.setInitialState(this.pda.initialState);
  }

  /**
   * Sets an initial stack symbol to the PDA
   * @param symbol A PDA stack symbol
   * @returns Nothing if the stack symbol is invalid
   */
  public setInitialStackSymbol(symbol: PDAStackSymbol): void {
    if (!this.pda.stackSymbols.has(symbol)) return;
    this.pda.initialStackSymbol = symbol;
    this.hook.setInitialStackSymbol(this.pda.initialStackSymbol);
  }

  /**
   * Adds a final state to the PDA
   * @param state A PDA state
   * @returns Nothing if the PDA state doesn't exist
   */
  public addFinalState(state: PDAState): void {
    if (!this.pda.states.has(state)) return;
    this.pda.finalStates.add(state);
    this.hook.setFinalStates(Array.from(this.pda.finalStates));
  }

  /**
   * Deletes a final state in the PDA
   * @param state A PDA state
   */
  public deleteFinalState(state: PDAState): void {
    this.pda.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.pda.finalStates));
  }

  /**
   * Gets the position of a PDA state
   * @param state A PDA state
   * @returns The position of the given PDA state
   */
  public getPosition(state: PDAState): PointXY {
    return this.ptpda.get(state);
  }

  /**
   * Sets the position of the given PDA state
   * @param state A PDA state
   * @param position The position of the given PDA state
   * @returns Nothing if the PDA state doesn't exist
   */
  public setPosition(state: PDAState, position: PointXY): void {
    if (!this.ptpda.has(state)) return;
    this.ptpda.set(state, position);
    this.hookPPDA(Array.from(this.ptpda));
  }
}

/**
 * Hook that returns a ReactivePDA instance from the given SerialDFA and previous PPFA.
 * @returns A tuple containing the SerialPDA, ReactivePDA and PositionsPDA instances
 */
export function useReactivePDA(): [SerialPDA, ReactivePDA, PositionsPDA] {
  const [states, setStates] = useState<PDAState[]>([]);
  const [inputSymbols, setInputSymbols] = useState<PDAInputSymbol[]>([]);
  const [stackSymbols, setStackSymbols] = useState<PDAStackSymbol[]>([]);
  const [transitions, setTransitions] = useState<
    [PDATransitionInput, PDATransitionOutput[]][]
  >([]);
  const [initialState, setInitialState] = useState<PDAState>();
  const [initialStackSymbol, setInitialStackSymbol] =
    useState<PDAStackSymbol>();
  const [finalStates, setFinalStates] = useState<PDAState[]>([]);
  const [ppda, setPPDA] = useState<[PDAState, PointXY][]>([]);

  /**
   * The SerialPDA instance
   */
  const spda: SerialPDA = {
    states,
    inputSymbols,
    stackSymbols,
    transitions,
    initialState,
    initialStackSymbol,
    finalStates,
  };

  /**
   * The HookSerialPDA instance
   */
  const hook: HookSerialPDA = {
    setStates,
    setInputSymbols,
    setStackSymbols,
    setTransitions,
    setInitialState,
    setInitialStackSymbol,
    setFinalStates,
  };

  /**
   * The PDA instance
   */
  const pda: PDA = {
    states: new Set(states),
    inputSymbols: new Set(inputSymbols),
    stackSymbols: new Set(stackSymbols),
    transitions: new ObjectMap(transitions),
    initialState,
    initialStackSymbol,
    finalStates: new Set(finalStates),
  };

  /**
   * The PositionTablePDA instance
   */
  const ptpda: PositionTablePDA = new ObjectMap(ppda);

  /**
   * The ReactivePDA instance
   */
  const rpda: ReactivePDA = new ReactivePDA(pda, hook, ptpda, setPPDA);

  return [spda, rpda, ppda];
}
