import {
  Button,
  Input,
  Stack,
  HStack,
  VStack,
  Heading,
  Kbd,
  Portal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  PopoverContent,
  PopoverTrigger,
  Tag,
  useDisclosure,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import {
  PDAInputSymbol,
  PDAStackSymbol,
  PDAState,
  ReactivePDA,
  SerialPDA,
} from "../../../lib/ReactivePDA";
import { useContext, useState } from "react";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
 * A component containing a popover window to set a new initial state
 * @param param0 The ReactivePDA instance
 * @returns A JSX component containing a popover window to set a new initial state
 */
function SetNewInitialStatePopover({ rpda }: { rpda: ReactivePDA }) {
  const [input, setInput] = useState<PDAState>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rpda.setInitialState(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          Set
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Initial State</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to set a new initial stack symbol
 * @param param0 The ReactivePDA instance
 * @returns A JSX component containing a popover window to set a new initial stack symbol
 */
function SetNewInitialStackSymbolPopover({ rpda }: { rpda: ReactivePDA }) {
  const [input, setInput] = useState<PDAStackSymbol>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rpda.setInitialStackSymbol(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          Set
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Initial Stack Symbol</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new final state
 * @param param0 The ReactivePDA instance
 * @returns A JSX component containing a popover window to add a new final state
 */
function AddNewFinalStatePopover({ rpda }: { rpda: ReactivePDA }) {
  const [input, setInput] = useState<PDAState>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rpda.addFinalState(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Final State</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new input symbol
 * @param param0 The ReactivePDA instance
 * @returns A JSX component containing a popover window to add a new input symbol
 */
function AddNewInputSymbolPopover({ rpda }: { rpda: ReactivePDA }) {
  const [input, setInput] = useState<PDAInputSymbol>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rpda.addInputSymbol(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Input Symbol</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing a popover window to add a new stack symbol
 * @param param0 The ReactivePDA instance
 * @returns A JSX component containing a popover window to add a new stack symbol
 */
function AddNewStackSymbolPopover({ rpda }: { rpda: ReactivePDA }) {
  const [input, setInput] = useState<PDAStackSymbol>("");
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = () => {
    rpda.addStackSymbol(input);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="xs" variant="outline">
          +
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>New Stack Symbol</PopoverHeader>
          <PopoverBody>
            <Stack>
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
              />
              <Button onClick={onSubmit}>Save</Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

/**
 * A component containing the debug console of PDA
 * @param param0 The SerialPDA and ReactivePDA instances
 * @returns A JSX component containing the debug console of PDA
 */
export function ConsolePDA({
  spda,
  rpda,
}: {
  spda: SerialPDA;
  rpda: ReactivePDA;
}) {
  const isActivated = useContext(ActivationContext);

  return (
    <VStack padding="0.5rem">
      <Heading fontSize="l">Initial State</Heading>
      <HStack>
        {spda.initialState !== undefined && <Tag>{spda.initialState}</Tag>}
        {!isActivated && <SetNewInitialStatePopover rpda={rpda} />}
      </HStack>
      <Heading fontSize="l">Initial Stack Symbol</Heading>
      <HStack>
        {spda.initialStackSymbol !== undefined && (
          <Kbd userSelect="none">
            {spda.initialStackSymbol === "" ? "ε" : spda.initialStackSymbol}
          </Kbd>
        )}
        {!isActivated && <SetNewInitialStackSymbolPopover rpda={rpda} />}
      </HStack>
      <Heading fontSize="l">Final States</Heading>
      <HStack>
        {spda.finalStates.map((state) => (
          <Tag key={state}>
            <TagLabel>{state}</TagLabel>
            {!isActivated && (
              <TagCloseButton onClick={() => rpda.deleteFinalState(state)} />
            )}
          </Tag>
        ))}
        {!isActivated && <AddNewFinalStatePopover rpda={rpda} />}
      </HStack>
      <Heading fontSize="l">Input Symbols</Heading>
      <HStack>
        {spda.inputSymbols.map((symbol) => (
          <Kbd
            userSelect="none"
            key={symbol}
            onDoubleClick={
              isActivated ? () => {} : () => rpda.deleteInputSymbol(symbol)
            }
          >
            {symbol === "" ? "ε" : symbol}
          </Kbd>
        ))}
        {!isActivated && <AddNewInputSymbolPopover rpda={rpda} />}
      </HStack>
      <Heading fontSize="l">Stack Symbols</Heading>
      <HStack>
        {spda.stackSymbols.map((symbol) => (
          <Kbd
            userSelect="none"
            key={symbol}
            onDoubleClick={
              isActivated ? () => {} : () => rpda.deleteStackSymbol(symbol)
            }
          >
            {symbol === "" ? "ε" : symbol}
          </Kbd>
        ))}
        {!isActivated && <AddNewStackSymbolPopover rpda={rpda} />}
      </HStack>
    </VStack>
  );
}
