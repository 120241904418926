import { useState } from "react";
import {
  TMState,
  TMTransitionInput,
  TMTransitionOutput,
  SerialTM,
  TMDirection,
} from "./ReactiveTM";
import { ObjectMap } from "./util/ObjectMap";

/**
 * Type representing a frame of a TM state and input strings
 */
export type FrameTM = readonly (readonly [
  TMState,
  readonly (readonly [string, string, TMDirection])[],
])[];

/**
 * Type representing an animation of a TM, with functions for controlling the animation
 */
export type AnimationTM = {
  init: (input: string) => void;
  stop: () => void;
  forward: () => void;
  backward: () => void;
  isValid: () => boolean;
  isPassed: () => boolean;
  isFailed: () => boolean;
};

/**
 * Check if the TM is valid
 * @param stm a SerialTM
 * @returns if the TM is valid
 */
export function validateTM(stm: SerialTM): boolean {
  // TODO: validate initialStates and finalStates
  return true;
}

/**
 * Computes the next frame of the TM animation given the last frame and the SerialTM object
 * @param stm SerialTM object representing the TM
 * @param lastFrame Last frame of the TM animation
 * @returns Next frame of the TM animation
 */
export function nextFrameTM(stm: SerialTM, lastFrame: FrameTM): FrameTM {
  const frame = new ObjectMap<
    TMState,
    readonly (readonly [string, string, TMDirection])[]
  >();
  const tapeSymbols = stm.tapeSymbols;
  const transitions = new ObjectMap<
    TMTransitionInput,
    readonly TMTransitionOutput[]
  >(stm.transitions);

  for (const [inputState, inputs] of lastFrame) {
    for (const [tapeLeft, tapeRight, direction] of inputs) {
      for (const tapeSymbol of tapeSymbols) {
        switch (direction) {
          case TMDirection.LEFT:
            if (tapeLeft.endsWith(tapeSymbol)) {
              const output = transitions.get([inputState, tapeSymbol]);
              if (output?.length > 0) {
                for (const [
                  outputState,
                  outputSymbol,
                  outputDirection,
                ] of output) {
                  const outputTapeLeft = tapeLeft.slice(
                    0,
                    tapeLeft.lastIndexOf(tapeSymbol),
                  );
                  const outputTapeRight = outputSymbol.concat(tapeRight);
                  const outputInputs = new Set(frame.get(outputState));
                  outputInputs.add([
                    outputTapeLeft,
                    outputTapeRight,
                    outputDirection,
                  ]);
                  frame.set(outputState, Array.from(outputInputs));
                }
              }
            }
            break;
          case TMDirection.RIGHT:
            if (tapeRight.startsWith(tapeSymbol)) {
              const output = transitions.get([inputState, tapeSymbol]);
              if (output?.length > 0) {
                for (const [
                  outputState,
                  outputSymbol,
                  outputDirection,
                ] of output) {
                  const outputTapeLeft = tapeLeft.concat(outputSymbol);
                  const outputTapeRight = tapeRight.replace(tapeSymbol, "");
                  const outputInputs = new Set(frame.get(outputState));
                  outputInputs.add([
                    outputTapeLeft,
                    outputTapeRight,
                    outputDirection,
                  ]);
                  frame.set(outputState, Array.from(outputInputs));
                }
              }
            }
            break;
        }
      }
    }
  }
  return Array.from(frame);
}

/**
 * Hook that returns the current frame of the TM animation and functions for controlling the animation
 * @param stm SerialTM object representing the TM
 * @returns Array containing the current frame of the TM animation and functions for controlling the animation
 */
export function useAnimationTM(stm: SerialTM): [FrameTM, AnimationTM] {
  const [ftm, setFTM] = useState<FrameTM>([]);
  const [htm, setHTM] = useState<readonly FrameTM[]>([]);

  /**
   * Initializes the TM animation with a given input string
   * @param input The given input string
   */
  const init = (input: string) => {
    setFTM([[stm.initialState, [["", input.concat(stm.blankSymbol), TMDirection.RIGHT]]]]);
    setHTM([]);
  };

  /**
   * Stops the TM animation
   */
  const stop = () => {
    if (ftm.length > 0) setFTM([]);
    if (htm.length > 0) setHTM([]);
  };

  /**
   * Moves the TM animation forward by one frame
   */
  const forward = () => {
    const nextFrame = nextFrameTM(stm, ftm);
    const history = Array.from(htm);
    history.push(ftm);
    setFTM(nextFrame);
    setHTM(history);
  };

  /**
   * Moves the TM animation backward by one frame
   * @returns Nothing if the last frame doesn't exist
   */
  const backward = () => {
    const history = Array.from(htm);
    const lastFrame = history.pop();
    if (!lastFrame) return;
    setFTM(lastFrame);
    setHTM(history);
  };

  /**
   * Checks the validity of the TM
   * @returns The validity of the TM
   */
  const isValid = () => validateTM(stm);

  /**
   * Checks the result if an input string can pass the input test of the TM
   * @returns True if the input string is passed, false otherwise
   */
  const isPassed = () =>
    // in final state
    ftm.some(([state]) => stm.finalStates.includes(state));

  /**
   * Checks the result of the input test is failed
   * @returns True if the input string is failed, false otherwise
   */
  const isFailed = () =>
    // stops
    ftm.length === 0 ||
    ftm.every(([, status]) => status.length === 0) ||
    // is same as the last frame
    JSON.stringify(ftm) === JSON.stringify(htm[-1]) ||
    // has a loop
    htm.some((frame) => JSON.stringify(frame) === JSON.stringify(ftm));

  return [
    ftm,
    {
      init,
      stop,
      forward,
      backward,
      isValid,
      isPassed,
      isFailed,
    },
  ];
}
