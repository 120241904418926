import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true,
  },
  styles: {
    global: {
      '*, *:before, *:after': {
        position: 'relative',
      },
      body: {
        overflow: 'hidden',
        overscrollBehavior: 'none',
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
      },
      '#root': {
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      },
      '.jtk-overlay': {
        zIndex: 9,
        cursor: 'pointer',
        userSelect: 'none',
        padding: '0 0.2rem',
        border: '1px solid',
        borderRadius: '0.5rem',
        background: 'white',
        color: 'black',
        fontSize: "sm",
      },
      '.jtk-hidden': {
        display: 'none',
      }
    },
  },
});
