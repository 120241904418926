import { HamburgerIcon } from '@chakra-ui/icons';
import {
  HStack,
  IconButton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import "./Header.css";

/**
 * The component containing a logo with style toggle functionality
 * @returns A JSX component containing a logo with style toggle functionality
 */
export function Logo() {
  const { toggleColorMode } = useColorMode();
  const isLightMode = useColorModeValue(true, false);

  return (
    <span className="Logo" onClick={toggleColorMode}>
      <b
        className="Logo-Text"
        style={{
          color: isLightMode ? "white" : "black",
        }}
      >
        AS
      </b>
      <b
        className="Logo-Text"
        style={{
          color: isLightMode ? "black" : "white",
        }}
      >
        KIT
      </b>
      <b
        className="Logo-Block"
        style={{
          left: isLightMode ? "0" : "3rem",
        }}
      ></b>
    </span>
  );
}

export function Header({
  isMenuVisible,
  setMenuVisible,
}: {
  isMenuVisible: boolean;
  setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <HStack
      height="3rem"
      justifyContent="space-between"
      width="100%"
      borderBottom="1px solid var(--chakra-colors-chakra-border-color)"
    >
      <Logo />
      <HStack right="0.5rem">
        <IconButton
          variant="outline"
          aria-label="Open menu"
          isActive={isMenuVisible}
          onClick={() => setMenuVisible(!isMenuVisible)}
          icon={<HamburgerIcon />}
        />
      </HStack>
    </HStack>
  );
}
