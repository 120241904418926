import {
  BrowserJsPlumbInstance,
  DragStopPayload,
  EVENT_DRAG_STOP,
} from "@jsplumb/browser-ui";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { PointXY } from "../../lib/util/PointXY";
import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { ActivationContext } from "../../lib/util/ActivationContext";

/**
 * A component representing a state in an automaton diagram.
 * Uses jsPlumb to enable drag-and-drop functionality
 * @param param0
 * @returns A JSX element representing the state with drag-and-drop behavior and a popover displaying status information
 */
export function AutomatonState({
  state,
  jsplumb,
  children,
  position: [x, y],
  isInitialState,
  isFinalState,
  hasTokens,
  hasEmptyToken,
  onDoubleClick,
  onDelete,
  updatePosition,
}: {
  state: string;
  jsplumb: BrowserJsPlumbInstance | undefined;
  children: ReactElement;
  position: PointXY;
  isInitialState: boolean;
  isFinalState: boolean;
  hasTokens: boolean;
  hasEmptyToken: boolean;
  onDoubleClick: () => void;
  onDelete: () => void;
  updatePosition: (pos: PointXY) => void;
}) {
  const isActivated = useContext(ActivationContext);

  const stateRef = useRef<HTMLDivElement | null>(null);
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const background = hasTokens
    ? hasEmptyToken && isFinalState
      ? "green.200"
      : "yellow.200"
    : "";
  const outline = isFinalState ? "1px solid" : "";
  const color = background !== "" ? "black" : "";

  const onMouseOver = () => {
    if (isActivated) return;
    setMouseOver(true);
  };

  const onMouseOut = () => {
    if (isActivated) return;
    setMouseOver(false);
  };

  useEffect(() => {
    const element = stateRef.current;
    if (!element || !jsplumb) return;
    jsplumb.manage(element, state);
    return () => {
      jsplumb.unmanage(element);
    };
  }, [jsplumb, state]);

  useEffect(() => {
    const element = stateRef.current;
    if (!element || !jsplumb || !updatePosition) return;
    jsplumb.bind(EVENT_DRAG_STOP, (payload: DragStopPayload) => {
      if (payload.el === element) {
        const pos: PointXY = [element.offsetLeft, element.offsetTop];
        updatePosition(pos);
      }
    });
  }, [jsplumb, updatePosition]);

  return (
    <Flex
      className="jsplumb-jtk-target"
      position="absolute"
      alignItems="center"
      justifyContent="center"
      height="96px"
      width="96px"
      borderRadius="48px"
      cursor={isActivated ? "default" : "grab"}
      ref={stateRef}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={{ left: x, top: y }}
      background={background}
      userSelect="none"
      outline={outline}
      outlineOffset="-8px"
      border="1px solid"
      color={color}
    >
      {isInitialState && <Text width="1rem">→</Text>}
      {hasTokens ? (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Text
              fontSize="lg"
              cursor={isActivated ? "default" : "move"}
              fontFamily="monospace"
            >
              {state}
            </Text>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Status</PopoverHeader>
              <PopoverBody>{children}</PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      ) : (
        <Text
          className="jsplumb-jtk-source"
          fontSize="lg"
          cursor={isActivated ? "default" : "move"}
          fontFamily="monospace"
        >
          {state}
        </Text>
      )}
      {isInitialState && <Text width="1rem">&nbsp;</Text>}
      <SmallCloseIcon
        display={mouseOver ? "block" : "none"}
        position="absolute"
        transform="translate(18px, -18px)"
        cursor="pointer"
        onClick={() => {
          jsplumb.unmanage(stateRef.current);
          onDelete();
        }}
      />
    </Flex>
  );
}
