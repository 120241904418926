import { BrowserJsPlumbInstance, ContainmentType, newInstance } from "@jsplumb/browser-ui";
import { StateMachineConnector } from "@jsplumb/connector-bezier";
import { ArrowOverlay, BlankEndpoint } from "@jsplumb/core";
import { useContext, useEffect, useState } from "react";
import { ActivationContext } from "../util/ActivationContext";

/** 
  * Hook that returns a reactive instance of jsPlumb 
  * @param containerRef React MutableRefObject pointing to the DOM element that will contain the JSPlumb instance. 
  * @returns BrowserJsPlumbInstance The reactive instance of JSPlumb. 
  */
export function useReactiveJSPlumb(
  containerRef: React.MutableRefObject<Element>,
): BrowserJsPlumbInstance {
  const isActivated = useContext(ActivationContext);
  const [jsplumb, setJSPlumb] = useState<BrowserJsPlumbInstance>();

  useEffect(() => {
    if (!containerRef.current) return;
    setJSPlumb(
      newInstance({
        container: containerRef.current as Element,
        connector: {
          type: StateMachineConnector.type,
          options: {
            curviness: 0,
          },
        },
        connectionsDetachable: false,
        endpoint: BlankEndpoint.type,
        connectionOverlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 0.8,
            },
          },
        ],
        dragOptions: {
          containment: ContainmentType.parentEnclosed,
          grid: { w: 24, h: 24 },
        },
        anchor: {
          type: 'Perimeter',
          options: {
            shape: 'Circle',
          },
        },
        anchors: ["Continuous", "Continuous"],
      }),
    );
  }, [containerRef]);

  useEffect(() => {
    const sourceSelector = jsplumb?.addSourceSelector(".jsplumb-jtk-source");
    const targetSelector = jsplumb?.addTargetSelector(".jsplumb-jtk-target");
    return () => {
      jsplumb?.removeSourceSelector(sourceSelector);
      jsplumb?.removeTargetSelector(targetSelector);
    }
  }, [jsplumb]);

  useEffect(() => {
    if (isActivated) {
      jsplumb?.addDragFilter("*");
    } else {
      jsplumb?.removeDragFilter("*");
    }
  }, [jsplumb, isActivated]);

  return jsplumb;
}
