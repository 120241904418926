/**
 * A Map-like class that accepts any object as a key by serializing it to JSON
 */
export class ObjectMap<K, V> {
  private _map;

  /**
   * Constructs a new ObjectMap
   * @param data Optional iniital data to add to the map
   */
  public constructor(data: readonly (readonly [K, V])[] = []) {
    this._map = new Map<string, V>();
    for (const [key, value] of data) {
      this.set(key, value);
    }
  }

  /**
   * Sets the value for a given key
   * @param key The key to set the value for
   * @param value The value to set
   * @returns This ObjectMap instance
   */
  public set(key: K, value: V): this {
    this._map.set(JSON.stringify(key), value);
    return this;
  }

  /**
   * Gets the value for a given key, or undefined if the key is not present in the map
   * @param key The key to retrieve the value for
   * @returns The value associated with the given key, or undefined
   */
  public get(key: K): V | undefined {
    return this._map.get(JSON.stringify(key));
  }

  /**
   * Deletes the value for a given key, returning true if the key was present in the map, or false otherwise
   * @param key The key to delete the value for
   * @returns True if the key was present in the map and its value was deleted, false otherwise
   */
  public delete(key: K): boolean {
    return this._map.delete(JSON.stringify(key));
  }

  /**
   * Check if the ObjectMap contains the given key
   * @param key The key to check for
   * @returns True if the map contains the given key, false otherwise
   */
  public has(key: K): boolean {
    return this._map.has(JSON.stringify(key));
  }

  /**
   * Returns an iterator over the key-value pairs in the map
   * @returns An iterator over the key-value pairs in the map
   */
  public [Symbol.iterator](): IterableIterator<[K, V]> {
    return Array.from(this._map)
      .map(([keyString, value]: [string, V]): [K, V] => [
        JSON.parse(keyString) as K,
        value,
      ])
      [Symbol.iterator]();
  }
}
