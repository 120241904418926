import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
  useClipboard,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Header } from "./components/Header";
import { Allotment } from "allotment";
import { useState } from "react";

import "allotment/dist/style.css";

import { GridPattern } from "./components/GridPattern";
import { Controls } from "./components/Controls";

import {
  AutomatonType,
  DataDFA,
  DataNFA,
  DataPDA,
  DataTM,
} from "../lib/util/AutomatonType";
import { ActivationContext } from "../lib/util/ActivationContext";

import { ObjectMap } from '../lib/util/ObjectMap';
import { PointXY } from '../lib/util/PointXY';

import { AnimaticDFA } from './panes/DFA/AnimaticDFA';
import { ConsoleDFA } from './panes/DFA/ConsoleDFA';
import { VisualDFA } from './panes/DFA/VisualDFA';
import { useAnimationDFA } from '../lib/AnimationDFA';
import {
  DFAInputSymbol,
  DFAState,
  useReactiveDFA,
} from '../lib/ReactiveDFA';

import { AnimaticNFA } from './panes/NFA/AnimaticNFA';
import { VisualNFA } from './panes/NFA/VisualNFA';
import { ConsoleNFA } from './panes/NFA/ConsoleNFA';
import { useAnimationNFA } from '../lib/AnimationNFA';
import {
  NFAInputSymbol,
  NFAState,
  NFATransitionInput,
  NFATransitionOutput,
  useReactiveNFA,
} from '../lib/ReactiveNFA';

import { AnimaticPDA } from "./panes/PDA/AnimaticPDA";
import { VisualPDA } from "./panes/PDA/VisualPDA";
import { ConsolePDA } from "./panes/PDA/ConsolePDA";
import { useReactivePDA } from "../lib/ReactivePDA";
import { useAnimationPDA } from "../lib/AnimationPDA";

import { AnimaticTM } from "./panes/TM/AnimaticTM";
import { VisualTM } from "./panes/TM/VisualTM";
import { ConsoleTM } from "./panes/TM/ConsoleTM";
import { TMDirection, useReactiveTM } from "../lib/ReactiveTM";
import { useAnimationTM } from "../lib/AnimationTM";

/**
 * The main component of ASKIT
 * @returns a React component
 */
export function ASKit() {
  const toast = useToast();

  const [isMenuVisible, setMenuVisible] = useState<boolean>(true);
  const [isActivated, setActivated] = useState<boolean>(false);

  const [importData, setImportData] = useState<string>("");
  const {
    onCopy: onExportDataCopy,
    value: exportData,
    setValue: setExportData,
    hasCopied: hasExportDataCopied,
  } = useClipboard("");

  const types = [
    AutomatonType.DFA,
    AutomatonType.NFA,
    AutomatonType.PDA,
    AutomatonType.TM,
  ];
  const [type, setType] = useState<AutomatonType>(AutomatonType.DFA);

  const [sdfa, rdfa, pdfa] = useReactiveDFA();
  const [fdfa, adfa] = useAnimationDFA(sdfa);

  const [snfa, rnfa, pnfa] = useReactiveNFA();
  const [fnfa, anfa] = useAnimationNFA(snfa);

  const [spda, rpda, ppda] = useReactivePDA();
  const [fpda, apda] = useAnimationPDA(spda);

  const [stm, rtm, ptm] = useReactiveTM();
  const [ftm, atm] = useAnimationTM(stm);

  const {
    isOpen: isImportModalOpen,
    onOpen: onImportModalOpen,
    onClose: onImportModalClose,
  } = useDisclosure();

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();

  /**
   * Imports an automaton
   */
  const importAutomaton = () => {
    onImportModalOpen();
  };

  /**
   * Resets an automaton
   */
  const resetAutomaton = () => {
    switch (type) {
      case AutomatonType.DFA:
        rdfa.importData();
        break;
      case AutomatonType.NFA:
        rnfa.importData();
        break;
      case AutomatonType.PDA:
        rpda.importData();
        break;
      case AutomatonType.TM:
        rtm.importData();
        break;
    }
    setActivated(false);
  };

  /**
   * Deploy a sample automaton
   */
  const deploySampleAutomaton = () => {
    switch (type) {
      case AutomatonType.DFA:
        rdfa.importData({
          type: AutomatonType.DFA,
          automaton: {
            states: ['q0', 'q1', 'q2','q3','q4','q5'],
            inputSymbols: ['0', '1'],
            initialState: 'q0',
            transitions: [
              [['q0', '0'], 'q3'],
              [['q1', '0'], 'q2'],
              [['q2', '0'], 'q2'],
              [['q3', '0'], 'q0'],
              [['q4', '0'], 'q2'],
              [['q5', '0'], 'q5'],
              [['q0', '1'], 'q1'],
              [['q1', '1'], 'q5'],
              [['q2', '1'], 'q5'],
              [['q3', '1'], 'q4'],
              [['q4', '1'], 'q5'],
              [['q5', '1'], 'q5'],
            ],
            finalStates: ['q2','q1','q4'],
          },
          positions: [
            ['q0', [96, 96*3]],
            ['q1', [96 * 3, 96*3]],
            ['q2', [96 * 5, 96*3]],
            ['q3', [96, 96]],
            ['q4', [96 * 3, 96]],
            ['q5', [96 * 5, 96]],
          ],
        });
        break;
      case AutomatonType.NFA:
        rnfa.importData({
          type: AutomatonType.NFA,
          automaton: {
            states: ["q0", "q1", "q2"],
            inputSymbols: ["a", "b"],
            initialStates: ["q0"],
            transitions: [
              [["q0", "a"], ["q1"]],
              [["q0", "b"], ["q1"]],
              [["q1", "a"], ["q2"]],
              [["q1", "b"], ["q2"]],
              [["q2", "a"], ["q2"]],
              [["q2", "b"], ["q2"]],
            ],
            finalStates: ["q2"],
          },
          positions: [
            ["q0", [96, 96]],
            ["q1", [96 * 3, 96]],
            ["q2", [96 * 5, 96]],
          ],
        });
        break;
      case AutomatonType.PDA:
        rpda.importData({
          type: AutomatonType.PDA,
          automaton: {
            states: ["q0", "q1", "q2"],
            inputSymbols: ["0", "1", ""],
            stackSymbols: ["0", "1", "#", ""],
            transitions: [
              [["q0", "0", "#"], [["q0", ["0", "#"]]]],
              [["q0", "1", "#"], [["q0", ["1", "#"]]]],
              [["q0", "0", "0"], [["q0", ["0", "0"]]]],
              [["q0", "1", "0"], [["q0", ["1", "0"]]]],
              [["q0", "0", "1"], [["q0", ["0", "1"]]]],
              [["q0", "1", "1"], [["q0", ["1", "1"]]]],
              [["q0", "", "#"], [["q1", ["#"]]]],
              [["q0", "", "0"], [["q1", ["0"]]]],
              [["q0", "", "1"], [["q1", ["1"]]]],
              [["q1", "0", "0"], [["q1", [""]]]],
              [["q1", "1", "1"], [["q1", [""]]]],
              [["q1", "", "#"], [["q2", [""]]]],
            ],
            initialState: "q0",
            initialStackSymbol: "#",
            finalStates: ["q2"],
          },
          positions: [
            ["q0", [48, 96]],
            ["q1", [336, 96]],
            ["q2", [192, 312]],
          ],
        });
        break;
      case AutomatonType.TM:
        rtm.importData({
          type: AutomatonType.TM,
          automaton: {
            states: ["q0", "q1"],
            symbols: [],
            tapeSymbols: ["0", "1", "$"],
            transitions: [
              [["q0", "0"], [["q0", "1", TMDirection.RIGHT]]],
              [["q0", "1"], [["q0", "0", TMDirection.RIGHT]]],
              [["q0", "$"], [["q1", "", TMDirection.LEFT]]],
            ],
            initialState: "q0",
            blankSymbol: "$",
            finalStates: ["q1"],
          },
          positions: [
            ["q0", [120, 96]],
            ["q1", [456, 96]],
          ],
        });
        break;
    }
    setActivated(false);
    setImportData('');
    onImportModalClose();
  };

  /**
   * Deploys an automaton
   */
  const deployAutomaton = () => {
    const showErrorToast = () => {
      toast({
        title: 'Unable to parse the automaton.',
        status: 'error',
        isClosable: true,
      });
    };
    switch (type) {
      case AutomatonType.DFA:
        try {
          rdfa.importData(JSON.parse(importData) as DataDFA);
        } catch (e) {
          showErrorToast();
        }
        break;
      case AutomatonType.NFA:
        try {
          rnfa.importData(JSON.parse(importData) as DataNFA);
        } catch (e) {
          showErrorToast();
        }
        break;
      case AutomatonType.PDA:
        try {
          rpda.importData(JSON.parse(importData) as DataPDA);
        } catch (e) {
          showErrorToast();
        }
        break;
      case AutomatonType.TM:
        try {
          rtm.importData(JSON.parse(importData) as DataTM);
        } catch (e) {
          showErrorToast();
        }
        break;
    }
    setActivated(false);
    setImportData('');
    onImportModalClose();
  };

  /**
   * Export an automaton
   */
  const exportAutomaton = () => {
    switch (type) {
      case AutomatonType.DFA:
        setExportData(
          JSON.stringify({
            type: AutomatonType.DFA,
            automaton: sdfa,
            positions: pdfa,
          }),
        );
        break;
      case AutomatonType.NFA:
        setExportData(
          JSON.stringify({
            type: AutomatonType.NFA,
            automaton: snfa,
            positions: pnfa,
          }),
        );
        break;
      case AutomatonType.PDA:
        setExportData(
          JSON.stringify({
            type: AutomatonType.PDA,
            automaton: spda,
            positions: ppda,
          }),
        );
        break;
      case AutomatonType.TM:
        setExportData(
          JSON.stringify({
            type: AutomatonType.TM,
            automaton: stm,
            positions: ptm,
          }),
        );
        break;
    }
    onExportModalOpen();
  };

  const DFAMinimisation = () => {
    let dfaTable: DFAState[][] = [];
    let dfaStates: DFAState[] = [];
    let positions: PointXY[] = [];
    dfaStates = [];
    positions = [];
    // This will assign the states and it will make it readable
    for(let i = 0; i<sdfa.states.length;i++){
      dfaStates[i] = sdfa.states[i];
      positions[i] = rdfa.getPosition(sdfa.states[i]);
      
    }
    
    let dfaFinal = sdfa.finalStates;
    let dfaTran = sdfa.transitions;
    let tableMap = new Map();

    // This will sort the states into alphabetical order
    dfaStates.sort();

    



    // This has an array of pairs and makes a table map with the pair being the key and then
    // the value is a string empyty
    for (let i=0; i<dfaStates.length-1; i++){
      for (let j=i+1;j<dfaStates.length;j++){
        let currentPair: DFAState[] = [dfaStates[i], dfaStates[j]];
        tableMap.set(currentPair,"empty");
        dfaTable.push(currentPair);
      }
    }

    // This goes through each pair and checks if there are any final states
    // if one of the pairs is a final state and the other is not a final state then it sets
    // the values to dis
    for (const pair of dfaTable){
      let x = 0;
      let y = 0;
      if (dfaFinal.filter((s)=> s === pair[0]).length !== 0) x = 1;
      if (dfaFinal.filter((s)=> s === pair[1]).length !== 0) y = 1;
      if (x !== y) tableMap.set(pair,"dis");

    }

  let inputsDone: string[] = [];

  for(const pair of dfaTable){
    if(tableMap.get(pair) === "dis"){
      continue;
    }else{

      let symbolList : string[];
      let outputStateList : string[];
      symbolList = [];
      outputStateList = [];
      
      // this gets all the output states and all the input symbols
      for(let i =0; i<sdfa.transitions.length; i++){ 
        const [[inputState, symbol], outputState] = sdfa.transitions[i];

        if(inputState === pair[0] || inputState === pair[1]){
          symbolList.push(symbol);
          outputStateList.push(outputState);
        }

      }
      
      // so we have the all the transitions for pair

      inputsDone = [];
      let index = 999;
      for(let i=0; i<symbolList.length; i++){
        for(let j=i+1; j<symbolList.length; j++){

          // if we have already done this transition then we can break
          if(inputsDone.includes(symbolList[i])){
            break;
          }

          // So this is for when they have the same input
          if(symbolList[i] === symbolList[j]){


            for(let k = 0; k<dfaTable.length;k++){
              if(outputStateList[i] === dfaTable[k][0] && outputStateList[j] === dfaTable[k][1] ){
                index = k;
              }
            }


            // Now we need to use the outputs to check if the state is distinguishable and we know this from the pair
            if( tableMap.get(dfaTable[index]) === "dis"){
              tableMap.set(pair,'dis');
            }else{

              if( tableMap.get(pair) === "empty"){
                tableMap.set(pair, '');
              }
              
              let oldString = tableMap.get(pair) + " " +  outputStateList[i] +" " + outputStateList[j] ; 
              tableMap.set(pair, oldString);
            }

          }

        }

      }

    }
    
  }


  let finished = false;
  let index2 = 9999;
  let isValid = true;

  while(finished === false){

    let holderMap = tableMap;
    finished = true;


    for(const pair of dfaTable){

      if(tableMap.get(pair) !== 'dis' && tableMap.get(pair) !== 'empty'){
        let stringholder = tableMap.get(pair);
        let array = stringholder.split(" ");

        for(let i=2; i<array.length;i+=2){
          let position1 = array[i-1];
          let position2 = array[i];

          for(let k = 0; k<dfaTable.length;k++){
            
            
            if(position1 === dfaTable[k][0] && position2 === dfaTable[k][1] ){
              index2 = k;
              isValid = true;
            }
            
          }
          if(tableMap.get(dfaTable[index2])=== 'dis' && isValid === true){
            
            tableMap.set(pair, 'dis');
            isValid = false;
          }

        
        
      }

    }

    // check if there has been any change to the map
    for(let [key,val] of holderMap){
      
      let holderkey = tableMap.get(key);
      if (holderkey !== val || (holderkey === undefined && !tableMap.has(key))){
        // This means that they are not the same the two maps so there has been a change
        finished = false; 
      }
    }

    }

  }

  let tracker = new Map();
  let newStateList : DFAState[]=[];
  let listOfPairs: DFAState[][] = [];
  let transInStateHolder : [DFAState, DFAInputSymbol];
  let transOutStateHolder : DFAState;
  let finalHolder : DFAState[];
  
  let initialHolder : DFAState;
  initialHolder = sdfa.initialState;


  newStateList =[];
  
  for(let i = 0; i<sdfa.states.length;i++){
    tracker.set(sdfa.states[i], false);
  }

  let counter=0;
  for(const pair of dfaTable){
    if(tableMap.get(pair) !== 'dis'){
      listOfPairs[counter] = pair;
      counter++;
    }
  }
  counter=0;
  for(const pair of listOfPairs){

    if(tracker.get(pair[0])===false && tracker.get(pair[1])===false){
      newStateList[counter] = pair[0]+ ','+pair[1];
      tracker.set(pair[0], true);
      tracker.set(pair[1], true);
      counter++;
    }
    if(tracker.get(pair[0]) === true && tracker.get(pair[1])===false ){
      for(let i=0; i<newStateList.length;i++){
        if(newStateList[i].includes(pair[0])){
          newStateList[i] = newStateList[i] +',' + pair[1];
          tracker.set(pair[1], true);
          
        }
      }
    }
    if(tracker.get(pair[0])===false &&tracker.get(pair[1]) === true){
      for(let i=0; i<newStateList.length;i++){
        if(newStateList[i].includes(pair[1])){
          newStateList[i] = newStateList[i] + ',' + pair[0];
          tracker.set(pair[0], true);
        }
      }
    }
  }

  for(let i=0; i<sdfa.states.length;i++){
    if(tracker.get(sdfa.states[i])=== false){
      newStateList[counter] = sdfa.states[i];
      counter++;
    }

  }

  newStateList.sort();

  finalHolder = [];
  for(let i=0; i<sdfa.finalStates.length;i++){
    finalHolder[i] = sdfa.finalStates[i];
  }
  for(let i = 0;i<sdfa.states.length;i++){
    rdfa.deleteState(sdfa.states[i]);
  }
  for(let i = 0;i<sdfa.transitions.length;i++){
    for(let j = 0; j<sdfa.inputSymbols.length;j++){
     rdfa.deleteTransition([[sdfa.states[i],sdfa.inputSymbols[j]]]);
    }
  }
  for(let i = 0;i<newStateList.length;i++){
    rdfa.addState(newStateList[i]);
    if(newStateList[i].includes(initialHolder)){
      rdfa.setInitialState(newStateList[i]);
    }
    
  }

  for(let i=0; i<finalHolder.length;i++){
    for(let j=0; j<newStateList.length;j++){
      if(newStateList[j].includes(finalHolder[i])){
        rdfa.addFinalState(newStateList[j]);
      }
    }
  }

  for(let i=0; i<dfaTran.length;i++){
    const [[inputState, symbol], outputState] = dfaTran[i];

    for(let j=0; j<newStateList.length;j++){
    
      if(newStateList[j].includes(inputState)){
        transInStateHolder = [newStateList[j],symbol];
      }
      if(newStateList[j].includes(outputState)){
        transOutStateHolder = newStateList[j];
        
      }
      
    }
    rdfa.addTransition([transInStateHolder, transOutStateHolder]);

  }


  for(let i=0; i<newStateList.length;i++){
    rdfa.setPosition(newStateList[i],positions[i]);
  }
}


  const calculateEpsilonClosure = (state: NFAState) => {
    const transitions = new ObjectMap<
      NFATransitionInput,
      readonly NFATransitionOutput[]
    >(snfa.transitions);
    const epsilonSymbol: NFAInputSymbol = '';
    let epsilonClosureStates: Set<NFAState> = new Set();
    epsilonClosureStates.add(state);

    for (const epsilonClosureState of epsilonClosureStates) {
      let visitedStates = transitions.get([epsilonClosureState, epsilonSymbol]);
      if (visitedStates?.length > 0) {
        for (const visitedState of visitedStates) {
          epsilonClosureStates.add(visitedState);
        }
      }
    }

    const epsilonClosure = Array.from(epsilonClosureStates).sort((a, b) => {
      const aIndex = parseInt(a.replace(/\D/g, ''));
      const bIndex = parseInt(b.replace(/\D/g, ''));

      return aIndex - bIndex;
    });
    return epsilonClosure;
  };

  const moveForward = (currentState: NFAState, inputSymbol: NFAInputSymbol) => {
    const transitions = new ObjectMap<
      NFATransitionInput,
      readonly NFATransitionOutput[]
    >(snfa.transitions);
    const epsilonClosure = currentState.split(',');
    let moveVisited: Set<NFAState> = new Set();

    for (const inputState of epsilonClosure) {
      const outputStates: readonly NFAState[] = transitions.get([
        inputState,
        inputSymbol,
      ]);
      if (outputStates?.length > 0) {
        for (const outputState of outputStates) {
          moveVisited.add(outputState);
        }
      }
    }
    const moveClosure = Array.from(moveVisited).sort((a, b) => {
      const aIndex = parseInt(a.replace(/\D/g, ''));
      const bIndex = parseInt(b.replace(/\D/g, ''));

      return aIndex - bIndex;
    });
    return moveClosure;
  };
  const translateNFA2DFA = () => {

    if (snfa.initialStates.length !== 1) return;
    // Calculate the epsilon closure of each NFA state
    const epsilonClosures: ObjectMap<NFAState, NFAState[]> = new ObjectMap();
    for (const state of snfa.states) {
      const epsilonClosure: NFAState[] = calculateEpsilonClosure(state);
      epsilonClosures.set(state, epsilonClosure);
    }
    let newStates: NFAState[] = [];
    let newStatesRecord: NFAState[] = [];
    // Calculate initial states after translation
    // let epsilonTransitionOutputs: NFAState[] = [];
    const initialEpsilonVisiteds: ObjectMap<NFAState, NFAState[]> =
      new ObjectMap();
    for (const initialState of snfa.initialStates) {
      const initialEpsilonVisited: NFAState[] = [];
      for (const state of epsilonClosures.get(initialState)) {
        if (state !== initialState) {
          initialEpsilonVisited.push(state);
        }
      }
      initialEpsilonVisiteds.set(initialState, initialEpsilonVisited);
    }
    let bannedList: number[] = [];
    for (let i = 0; i < snfa.initialStates.length; i++) {
      if (bannedList.includes(i)) {
        continue;
      }
      let newState: NFAState = epsilonClosures
        .get(snfa.initialStates[i])
        .join(',');
      for (let j = i + 1; j < snfa.initialStates.length; j++) {
        if (
          initialEpsilonVisiteds.get(snfa.initialStates[i]).join(',') ===
          initialEpsilonVisiteds.get(snfa.initialStates[j]).join(',')
        ) {
          newState +=
            ',' + initialEpsilonVisiteds.get(snfa.initialStates[j]).join(',');
          bannedList.push(j);
        }
        console.log(newState);
        newState = Array.from(new Set(newState))
          .sort((a, b) => {
            const aIndex = parseInt(a.replace(/\D/g, ''));
            const bIndex = parseInt(b.replace(/\D/g, ''));

            return aIndex - bIndex;
          })
          .join(',');
      }
      // In this case, the new state can't be duplicated
      newStates.push(newState);
      newStatesRecord.push(newState);
      rnfa.addState(newState);
      rnfa.addInitialState(newState);
    }
    // Keep move forward until no new state are added
    while (newStates.length > 0) {
      const stateToMove = newStates.shift();
      for (const inputSymbol of snfa.inputSymbols) {
        if (inputSymbol === '') continue;
        const moveClosure = moveForward(stateToMove, inputSymbol);
        let newStateSet: Set<NFAState> = new Set();
        let newState: NFAState;
        for (const moveVisitedState of moveClosure) {
          for (const item of epsilonClosures.get(moveVisitedState)) {
            newStateSet.add(item);
          }
        }
        newState = Array.from(newStateSet)
          .sort((a, b) => {
            const aIndex = parseInt(a.replace(/\D/g, ''));
            const bIndex = parseInt(b.replace(/\D/g, ''));

            return aIndex - bIndex;
          })
          .join(',');
        if (!newStatesRecord.includes(newState) && newState !== '') {
          newStates.push(newState);
          newStatesRecord.push(newState);
          rnfa.addState(newState);
          let isFinalState = false;
          for (const finalState of snfa.finalStates) {
            if (newState.split(',').includes(finalState)) {
              isFinalState = true;
            }
          }
          if (isFinalState && !snfa.finalStates.includes(newState)) {
            rnfa.addFinalState(newState);
          }
        }
        rnfa.addTransition([[stateToMove, inputSymbol], newState]);
      }
    }
    for (const state of snfa.states) {
      if (!newStatesRecord.includes(state)) {
        rnfa.deleteState(state);
      }
    }
  };

  return (
    <ActivationContext.Provider value={isActivated}>
      <Box display="flex" flexDirection="column" height="100vh">
        <Header isMenuVisible={isMenuVisible} setMenuVisible={setMenuVisible} />
        <Allotment
          onVisibleChange={(index, visible) => {
            if (index === 1) setMenuVisible(visible);
          }}
        >
          <Allotment.Pane>
            <GridPattern />
            <Controls />
            {type === AutomatonType.DFA && (
              <VisualDFA sdfa={sdfa} rdfa={rdfa} pdfa={pdfa} fdfa={fdfa} />
            )}
            {type === AutomatonType.NFA && (
              <VisualNFA snfa={snfa} rnfa={rnfa} pnfa={pnfa} fnfa={fnfa} />
            )}
            {type === AutomatonType.PDA && (
              <VisualPDA spda={spda} rpda={rpda} ppda={ppda} fpda={fpda} />
            )}
            {type === AutomatonType.TM && (
              <VisualTM stm={stm} rtm={rtm} ptm={ptm} ftm={ftm} />
            )}
          </Allotment.Pane>
          <Allotment.Pane
            maxSize={480}
            minSize={300}
            snap
            visible={isMenuVisible}
          >
            <Allotment vertical>
              <Allotment.Pane preferredSize={200} snap>
                <VStack padding="0.5rem">
                  <Heading fontSize="l">Type</Heading>
                  <ButtonGroup size="sm" isAttached>
                    {types.map((t) => (
                      <Button
                        key={t}
                        isActive={t === type}
                        onClick={() => {
                          setType(t);
                          setActivated(false);
                        }}
                      >
                        {AutomatonType[t]}
                      </Button>
                    ))}
                  </ButtonGroup>
                  <Heading fontSize="l">Actions</Heading>
                  <ButtonGroup size="sm">
                    {/* TODO: Lean support */}
                    <Button size="sm" onClick={resetAutomaton}>
                      Reset
                    </Button>
                    <Button size="sm" onClick={importAutomaton}>
                      Import
                    </Button>
                    <Button size="sm" onClick={exportAutomaton}>
                      Export
                    </Button>
                  </ButtonGroup>
                  {type === AutomatonType.NFA && (
                    <Button size="sm" onClick={translateNFA2DFA}>
                      Translate to DFA
                    </Button>
                  )}
                  {type === AutomatonType.DFA && (
                    <Button size="sm" onClick={DFAMinimisation}>
                      Minimise DFA
                    </Button>
                  )}
                </VStack>
              </Allotment.Pane>
              <Allotment.Pane preferredSize={240} snap>
                {type === AutomatonType.DFA && (
                  <AnimaticDFA adfa={adfa} setActivated={setActivated} />
                )}
                {type === AutomatonType.NFA && (
                  <AnimaticNFA anfa={anfa} setActivated={setActivated} />
                )}
                {type === AutomatonType.PDA && (
                  <AnimaticPDA apda={apda} setActivated={setActivated} />
                )}
                {type === AutomatonType.TM && (
                  <AnimaticTM atm={atm} setActivated={setActivated} />
                )}
              </Allotment.Pane>
              <Allotment.Pane>
                {type === AutomatonType.DFA && (
                  <ConsoleDFA sdfa={sdfa} rdfa={rdfa} />
                )}
                {type === AutomatonType.NFA && (
                  <ConsoleNFA snfa={snfa} rnfa={rnfa} />
                )}
                {type === AutomatonType.PDA && (
                  <ConsolePDA spda={spda} rpda={rpda} />
                )}
                {type === AutomatonType.TM && <ConsoleTM stm={stm} rtm={rtm} />}
              </Allotment.Pane>
            </Allotment>
          </Allotment.Pane>
        </Allotment>
      </Box>
      <Modal isOpen={isImportModalOpen} onClose={onImportModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={importData}
              onChange={(e) => setImportData(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={deploySampleAutomaton}>
              Sample
            </Button>
            <Button colorScheme="blue" onClick={deployAutomaton}>
              Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isExportModalOpen} onClose={onExportModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={exportData}
              onClick={(e) => (e.target as HTMLTextAreaElement).select()}
              readOnly
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onExportDataCopy}>
              {hasExportDataCopied ? "Copied!" : "Copy"}
            </Button>
            <Button colorScheme="blue" onClick={onExportModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ActivationContext.Provider>
  );
}
