import { useState } from "react";
import {
  DFAState,
  DFATransitionInput,
  DFATransitionOutput,
  SerialDFA,
} from "./ReactiveDFA";
import { ObjectMap } from "./util/ObjectMap";

/**
 * Type representing a frame of DFA states and input strings
 */
export type FrameDFA = readonly (readonly [DFAState, readonly string[]])[];

/**
 * Type representing an animation of a DFA, with functions for controlling the animation
 */
export type AnimationDFA = {
  /**
   * Initialize the DFA animation with a given input string
   * @param input Input string to initialize the DFA animation
   */
  init: (input: string) => void;
  /**
   * Stop the DFA animation
   */
  stop: () => void;
  /**
   * Move the DFA animation forward by one frame
   */
  forward: () => void;
  /**
   * Move the DFA animation backward by one frame
   */
  backward: () => void;
  /**
   * Check if the DFA is valid
   * @see validateDFA
   * @returns if the DFA is valid
   */
  isValid: () => boolean;
  /**
   * Check if the current frame is passed
   * @returns if the current frame has an acceptiong state
   */
  isPassed: () => boolean;
  /**
   * Check if the current frame is failed
   * @returns if the current frame is failed
   */
  isFailed: () => boolean;
};

/**
 * Check if the DFA is valid
 * @param sdfa a SerialDFA
 * @returns if the DFA is valid
 */
export function validateDFA(sdfa: SerialDFA): boolean {
  const isInitialStateValid = [sdfa.initialState].every(
    (initialState) =>
      initialState !== undefined && sdfa.states.includes(initialState),
  );
  const areTransitionsValid = [...sdfa.transitions].every(
    ([[inputState, inputSymbol], outputState]) =>
      sdfa.states.includes(inputState) &&
      sdfa.states.includes(outputState) &&
      sdfa.inputSymbols.includes(inputSymbol),
  );
  const areFinalStatesValid = [...sdfa.finalStates].every(
    (finalState) =>
      finalState !== undefined && sdfa.states.includes(finalState),
  );
  const areTransitionsComplete = [...sdfa.states].every((state) =>
    [...sdfa.inputSymbols].every((symbol) =>
      sdfa.transitions.some(
        ([[inputState, inputSymbol]]) =>
          inputState === state && inputSymbol === symbol,
      ),
    ),
  );
  return (
    isInitialStateValid &&
    areTransitionsValid &&
    areFinalStatesValid &&
    areTransitionsComplete
  );
}

/**
 * Computes the next frame of the DFA animation given the last frame and the SerialDFA object
 * @param sdfa SerialDFA object representing the DFA
 * @param lastFrame Last frame of the DFA animation
 * @returns Next frame of the DFA animation
 */
export function nextFrameDFA(sdfa: SerialDFA, lastFrame: FrameDFA): FrameDFA {
  const frame = new ObjectMap<DFAState, readonly string[]>();
  const inputSymbols = sdfa.inputSymbols;
  const transitions = new ObjectMap<DFATransitionInput, DFATransitionOutput>(
    sdfa.transitions,
  );

  for (const [inputState, inputs] of lastFrame) {
    for (const inputString of inputs) {
      for (const inputSymbol of inputSymbols) {
        if (inputString.startsWith(inputSymbol)) {
          const outputState = transitions.get([inputState, inputSymbol]);
          if (outputState !== undefined) {
            const outputInputs = new Set(frame.get(outputState));
            outputInputs.add(inputString.replace(inputSymbol, ""));
            frame.set(outputState, Array.from(outputInputs));
          }
        }
      }
    }
  }
  return Array.from(frame);
}

/**
 * Hook that returns the current frame of the DFA animation and functions for controlling the animation
 * @param sdfa SerialDFA object representing the DFA
 * @returns Array containing the current frame of the DFA animation and functions for controlling the animation
 */
export function useAnimationDFA(sdfa: SerialDFA): [FrameDFA, AnimationDFA] {
  const [fdfa, setFDFA] = useState<FrameDFA>([]);
  const [hdfa, setHDFA] = useState<readonly FrameDFA[]>([]);

  /**
   * Initializes the DFA animation with a given input string
   * @param input The given input string
   */
  const init = (input: string) => {
    setFDFA([[sdfa.initialState, [input]]]);
    setHDFA([]);
  };

  /**
   * Stops the DFA animation
   */
  const stop = () => {
    if (fdfa.length > 0) setFDFA([]);
    if (hdfa.length > 0) setHDFA([]);
  };

  /**
   * Moves the DFA animation forward by one frame
   */
  const forward = () => {
    const nextFrame = nextFrameDFA(sdfa, fdfa);
    const history = Array.from(hdfa);
    history.push(fdfa);
    setFDFA(nextFrame);
    setHDFA(history);
  };

  /**
   * Moves the DFA animation backward by one frame
   * @returns Nothing if the last frame doesn't exist
   */
  const backward = () => {
    const history = Array.from(hdfa);
    const lastFrame = history.pop();
    if (!lastFrame) return;
    setFDFA(lastFrame);
    setHDFA(history);
  };

  /**
   * Checks the validity of the DFA
   * @returns The validity of the DFA
   */
  const isValid = () => validateDFA(sdfa);

  /**
   * Checks the result if an input string can pass the input test of the DFA
   * @returns True if the input string is passed, false otherwise
   */
  const isPassed = () =>
    fdfa.some(
      ([state, inputs]) =>
        sdfa.finalStates.includes(state) &&
        inputs.some((token) => token.length === 0),
    );

  /**
   * Checks the result of the input test is failed
   * @returns True if the input string is failed, false otherwise
   */
  const isFailed = () =>
    // has no token
    fdfa.length === 0 ||
    // is same as the last frame
    JSON.stringify(fdfa) === JSON.stringify(hdfa[-1]) ||
    // has all states failed
    fdfa.every(
      ([state, inputs]) =>
        !sdfa.finalStates.includes(state) &&
        inputs.some((token) => token.length === 0),
    ) ||
    // has a loop
    hdfa.some((frame) => JSON.stringify(frame) === JSON.stringify(fdfa));

  return [
    fdfa,
    {
      init,
      stop,
      forward,
      backward,
      isValid,
      isPassed,
      isFailed,
    },
  ];
}
