import { useState } from 'react';
import { questionBank } from './QuestionBank';
import { Box, Button, Heading } from '@chakra-ui/react';
import { QuestionCard } from './QuestionCard';

/**
 * The Type of a question
 */
export type Question = {
  correct_answer: string;
  difficulty: string;
  incorrect_answers: string[];
  question: string;
  type: string;
  image: string;
};

/**
 * The type of a question state
 */
type QuestionState = {
  correct_answer: string;
  difficulty: string;
  incorrect_answers: string[];
  question: string;
  type: string;
  image: string;
  answers: any[];
};

/**
 * The type of an answer object
 */
export type AnswerObject = {
  question: string;
  answer: string;
  correct: boolean;
  correctAnswer: string;
};

/**
 * The enumeration of possible difficulties
 */
enum Difficulty {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
}

const initState: QuestionState = {
  correct_answer: "",
  difficulty: "",
  incorrect_answers: [""],
  question: "",
  type: "",
  image: "",
  answers: [""],
};

const TOTAL_QUESTIONS = 10;

/**
 * Fetches quiz questions from the question bank
 * @param amount The amount of questions
 * @param difficulty The difficulty of the questions
 * @returns An array of quiz questions with the given difficulty
 */
const fetchQuizQuestions = async (
  amount: number,
  difficulty: Difficulty,
): Promise<QuestionState[]> => {
  let holderNumber: number[] = [];
  let questionArray: Question[] = [];
  let finalArray: QuestionState[] = [];

  for (let i = 0; i < amount; i++) {
    let num = Math.floor(Math.random() * questionBank.length);
    holderNumber[i] = num;

    for (let j = 0; j < holderNumber.length; j++) {
      if (holderNumber[j] === holderNumber[i] && i !== j) {
        i--;
      }
    }
  } // This array will get the amount of random numbers needed these will be from the size of the question banl

  for (let i = 0; i < amount; i++) {
    questionArray[i] = questionBank[holderNumber[i]];
    finalArray[i] = initState;
  }
  //This gets the questions from the question bank

  finalArray = questionArray.map((question: Question) => ({
    ...question,
    answers: shuffleArray([
      ...question.incorrect_answers,
      question.correct_answer,
    ]),
  }));

  return finalArray;
};

/**
 * Shuffles an array
 * @param array
 * @returns A shuffled array
 */
const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

/**
 * A component containing a quiz with multiple choice questions
 * @returns A JSX component containing a quiz with multiple choice questions
 */
export const Quiz = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<QuestionState[]>([]);
  const [number, setNumber] = useState(0);
  const [userAnswers, setUserAnswers] = useState<AnswerObject[]>([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(true);

  /**
   * Starts the quiz
   */
  const startQuiz = async () => {
    setLoading(true);
    setGameOver(false);

    const newQuestions = await fetchQuizQuestions(
      TOTAL_QUESTIONS,
      Difficulty.EASY,
    );

    setQuestions(newQuestions);
    setScore(0);
    setUserAnswers([]);
    setNumber(0);
    setLoading(false);
  };

  /**
   * Checks the answer
   * @param selectedAnswer The answer that user select
   */
  const checkAnswer = (selectedAnswer: string) => {
    if (!gameOver) {
      // Check answer against correct answer
      const correct: boolean =
        questions[number].correct_answer === selectedAnswer;
      // Add score if answer is correct
      if (correct) setScore((prev) => prev + 1);
      // Save the answer in the array for user answers
      const answerObject: AnswerObject = {
        question: questions[number].question,
        answer: selectedAnswer,
        correct,
        correctAnswer: questions[number].correct_answer,
      };
      setUserAnswers((prev) => [...prev, answerObject]);
    }
  };

  /**
   * Finds the next question
   */
  const nextQuestion = () => {
    // Move on to the next question if not the last question
    const nextQ = number + 1;

    if (nextQ === TOTAL_QUESTIONS) {
      setGameOver(true);
    } else {
      setNumber(nextQ);
    }
  };

  return (
    <Box maxW="5xl" overflow="hidden">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="0.5rem"
          >
            <Heading
              as="h2"
              fontSize="3xl"
              marginBottom="0.5rem"
              textAlign="center"
            >
              AUTOMATA QUIZ
            </Heading>
            {gameOver || userAnswers.length === TOTAL_QUESTIONS ? (
              <Button
                className="Quiz-Button"
                onClick={startQuiz}
                colorScheme="teal"
              >
                Start
              </Button>
            ) : null}
            {!gameOver ? (
              <Heading
                as="h2"
                fontSize="xl"
                marginBottom="0.5rem"
                textAlign="center"
              >
                Score: {score}
              </Heading>
            ) : null}
            {loading && (
              <Heading
                as="h2"
                fontSize="xl"
                marginBottom="0.5rem"
                textAlign="center"
              >
                Loading Questions ...
              </Heading>
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding="0.5rem"
            >
              {!loading && !gameOver && (
                <QuestionCard
                  questionNr={number + 1}
                  totalQuestions={TOTAL_QUESTIONS}
                  question={questions[number].question}
                  questionImage={questions[number].image}
                  answers={questions[number].answers}
                  userAnswer={userAnswers ? userAnswers[number] : undefined}
                  callback={checkAnswer}
                />
              )}
            </Box>
            {!gameOver &&
            !loading &&
            userAnswers.length === number + 1 &&
            number !== TOTAL_QUESTIONS - 1 ? (
              <Button
                className="Quiz-Button"
                onClick={nextQuestion}
                colorScheme="teal"
              >
                Next Question
              </Button>
            ) : null}
          </Box>
    </Box>
  );
};
