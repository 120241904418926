import { useState } from "react";
import {
  PDAState,
  PDATransitionInput,
  PDATransitionOutput,
  SerialPDA,
} from "./ReactivePDA";
import { ObjectMap } from "./util/ObjectMap";

/**
 * Type representing a frame of a PDA state and input strings
 */
export type FramePDA = readonly (readonly [
  PDAState,
  readonly (readonly [string, readonly string[]])[],
])[];

/**
 * Type representing an animation of a PDA, with functions for controlling the animation
 */
export type AnimationPDA = {
  init: (input: string) => void;
  stop: () => void;
  forward: () => void;
  backward: () => void;
  isValid: () => boolean;
  isPassed: () => boolean;
  isFailed: () => boolean;
};

/**
 * Check if the PDA is valid
 * @param spda a SerialPDA
 * @returns if the PDA is valid
 */
export function validatePDA(spda: SerialPDA): boolean {
  // TODO: validate initialStates and finalStates
  const areTransitionsValid = [...spda.transitions].every(
    ([[inputState, inputSymbol], output]) =>
      spda.states.includes(inputState) &&
      [...output].every(
        ([outputState, outputStackSymbols]) =>
          spda.states.includes(outputState) &&
          outputStackSymbols.every((outputStackSymbol) =>
            spda.stackSymbols.includes(outputStackSymbol),
          ),
      ) &&
      spda.inputSymbols.includes(inputSymbol),
  );
  return areTransitionsValid;
}

/**
 * Computes the next frame of the PDA animation given the last frame and the SerialPDA object
 * @param spda SerialPDA object representing the PDA
 * @param lastFrame Last frame of the PDA animation
 * @returns Next frame of the PDA animation
 */
export function nextFramePDA(spda: SerialPDA, lastFrame: FramePDA): FramePDA {
  const frame = new ObjectMap<
    PDAState,
    readonly (readonly [string, readonly string[]])[]
  >();
  const inputSymbols = spda.inputSymbols;
  const transitions = new ObjectMap<
    PDATransitionInput,
    readonly PDATransitionOutput[]
  >(spda.transitions);

  for (const [inputState, inputs] of lastFrame) {
    for (const [inputString, inputStack] of inputs) {
      for (const inputSymbol of inputSymbols) {
        if (inputString.startsWith(inputSymbol)) {
          const stack = Array.from(inputStack);
          const stackSymbol = stack.pop() || "";
          const output = transitions.get([
            inputState,
            inputSymbol,
            stackSymbol,
          ]);
          if (output?.length > 0) {
            for (const [outputState, outputStackSymbols] of output) {
              const outputInputs = new Set(frame.get(outputState));
              outputInputs.add([
                inputString.replace(inputSymbol, ""),
                stack.concat(
                  Array.from(outputStackSymbols)
                    .filter((symbol) => symbol !== "")
                    .reverse(),
                ),
              ]);
              frame.set(outputState, Array.from(outputInputs));
            }
          }
        }
      }
    }
  }
  return Array.from(frame);
}

/**
 * Hook that returns the current frame of the PDA animation and functions for controlling the animation
 * @param spda SerialPDA object representing the PDA
 * @returns Array containing the current frame of the PDA animation and functions for controlling the animation
 */
export function useAnimationPDA(spda: SerialPDA): [FramePDA, AnimationPDA] {
  const [fpda, setFPDA] = useState<FramePDA>([]);
  const [hpda, setHPDA] = useState<readonly FramePDA[]>([]);

  /**
   * Initializes the PDA animation with a given input string
   * @param input The given input string
   */
  const init = (input: string) => {
    setFPDA([[spda.initialState, [[input, [spda.initialStackSymbol]]]]]);
    setHPDA([]);
  };

  /**
   * Stops the PDA animation
   */
  const stop = () => {
    if (fpda.length > 0) setFPDA([]);
    if (hpda.length > 0) setHPDA([]);
  };

  /**
   * Moves the PDA animation forward by one frame
   */
  const forward = () => {
    const nextFrame = nextFramePDA(spda, fpda);
    const history = Array.from(hpda);
    history.push(fpda);
    setFPDA(nextFrame);
    setHPDA(history);
  };

  /**
   * Moves the PDA animation backward by one frame
   * @returns Nothing if the last frame doesn't exist
   */
  const backward = () => {
    const history = Array.from(hpda);
    const lastFrame = history.pop();
    if (!lastFrame) return;
    setFPDA(lastFrame);
    setHPDA(history);
  };

  /**
   * Checks the validity of the PDA
   * @returns The validity of the PDA
   */
  const isValid = () => validatePDA(spda);

  /**
   * Checks the result if an input string can pass the input test of the PDA
   * @returns True if the input string is passed, false otherwise
   */
  const isPassed = () =>
    // Acceptance by final state
    fpda.some(
      ([state, inputs]) =>
        spda.finalStates.includes(state) &&
        inputs.some(([token]) => token.length === 0),
    ) ||
    // Acceptance by empty stack
    fpda.some(([, inputs]) =>
      inputs.some(([token, stack]) => token.length === 0 && stack.length === 0),
    );

  /**
   * Checks the result of the input test is failed
   * @returns True if the input string is failed, false otherwise
   */
  const isFailed = () =>
    // has no token
    fpda.length === 0 ||
    fpda.every(([, inputs]) => inputs.length === 0) ||
    // is same as the last frame
    JSON.stringify(fpda) === JSON.stringify(hpda[-1]) ||
    // has a loop
    hpda.some((frame) => JSON.stringify(frame) === JSON.stringify(fpda));

  return [
    fpda,
    {
      init,
      stop,
      forward,
      backward,
      isValid,
      isPassed,
      isFailed,
    },
  ];
}
