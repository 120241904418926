import { PositionsDFA, SerialDFA } from "../ReactiveDFA";
import { PositionsNFA, SerialNFA } from "../ReactiveNFA";
import { PositionsPDA, SerialPDA } from "../ReactivePDA";
import { PositionsTM, SerialTM } from "../ReactiveTM";

/**
 * An enumeration of possible automaton types.
 */
export enum AutomatonType {
  /**
   * A deterministic finite automaton.
   */
  DFA = "DFA",
  /**
   * A nondeterministic finite automaton.
   */
  NFA = "NFA",
  /**
   * A pushdown automaton.
   */
  PDA = "PDA",
  /**
   * A Turing machine
   */
  TM = "TM",
}

/**
 * The type that contains the data of a DFA
 */
export type DataDFA = {
  type: AutomatonType.DFA;
  automaton: SerialDFA;
  positions: PositionsDFA;
};

/**
 * The type that contains the data of an NFA
 */
export type DataNFA = {
  type: AutomatonType.NFA;
  automaton: SerialNFA;
  positions: PositionsNFA;
};

/**
 * The type that contains the data of a PDA
 */
export type DataPDA = {
  type: AutomatonType.PDA;
  automaton: SerialPDA;
  positions: PositionsPDA;
};

/**
 * The type that contains the data of a TM
 */
export type DataTM = {
  type: AutomatonType.TM;
  automaton: SerialTM;
  positions: PositionsTM;
};
