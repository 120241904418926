import { useContext, useEffect, useRef, useState } from "react";
import { AutomatonState } from "../../components/AutomatonState";

import {
  NFAInputSymbol,
  NFAState,
  PositionsNFA,
  ReactiveNFA,
  SerialNFA,
} from "../../../lib/ReactiveNFA";
import {
  Box,
  Button,
  Code,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useReactiveJSPlumb } from "../../../lib/jsplumb/ReactiveJSPlumb";
import { Connection, EVENT_CONNECTION, LabelOverlay } from "@jsplumb/core";
import { EVENT_CONNECTION_DBL_CLICK } from "@jsplumb/browser-ui";
import { FrameNFA } from "../../../lib/AnimationNFA";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
 * A component containing the modal to edit the transition
 * @param param0
 * @returns A JSX component containing the modal to edit the transition
 */
function EditTransitionModal({
  rnfa,
  openModalRef,
  closeModalRef,
}: {
  rnfa: ReactiveNFA;
  openModalRef: React.MutableRefObject<(conn: Connection) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<NFAInputSymbol>("");
  const [connection, setConnection] = useState<Connection>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    const label = (connection?.overlays?.inputSymbol as LabelOverlay)?.labelText;
    if (label) {
      rnfa.modifyTransitionSymbol(
        [[connection.sourceId, label], connection.targetId],
        input,
      );
    } else {
      rnfa.addTransition([[connection.sourceId, input], connection.targetId]);
    }
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (conn: Connection) => {
      const label =
        (conn?.overlays?.inputSymbol as LabelOverlay)?.labelText || "";
      if (label) setInput(label);
      setConnection(conn);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Transition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Source</FormLabel>
            <Input value={connection?.sourceId} disabled />
            <FormLabel>Target</FormLabel>
            <Input value={connection?.targetId} disabled />
            <FormLabel>Symbol</FormLabel>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the modal to edit the state
 * @param param0
 * @returns A JSX component containing the modal to edit the state
 */
function EditStateModal({
  rnfa,
  openModalRef,
  closeModalRef,
}: {
  rnfa: ReactiveNFA;
  openModalRef: React.MutableRefObject<(state: NFAState) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<NFAState>("");
  const [state, setState] = useState<NFAState>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    rnfa.renameState(state, input);
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (state: NFAState) => {
      setInput(state);
      setState(state);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit State</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the NFA visually
 * @param param0 The SerialNFA, ReactiveNFA, PositionsNFA and FrameNFA instances
 * @returns A JSX component containing the NFA visually
 */
export function VisualNFA({
  snfa,
  rnfa,
  pnfa,
  fnfa,
}: {
  snfa: SerialNFA;
  rnfa: ReactiveNFA;
  pnfa: PositionsNFA;
  fnfa: FrameNFA;
}) {
  const isActivated = useContext(ActivationContext);

  const containerRef = useRef<HTMLDivElement>();
  const jsplumb = useReactiveJSPlumb(containerRef);

  const openEditTransitionModalRef = useRef<(conn: Connection) => void>(
    () => {},
  );
  const closeEditTransitionModalRef = useRef<() => void>(() => {});

  const openEditStateModalRef = useRef<(state: NFAState) => void>(() => {});
  const closeEditStateModalRef = useRef<() => void>(() => {});

  const dblClickRenameState = (state: NFAState) => {
    if (isActivated) return;
    openEditStateModalRef.current(state);
  };

  const dblClickAddState = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isActivated) return;
    if (e.target === containerRef.current) {
      let number = 0;
      while (snfa.states.includes(`q${number}`)) number++;
      const state = `q${number}`;
      const rect = containerRef.current.getBoundingClientRect();
      rnfa.addState(state);
      rnfa.setPosition(state, [
        e.clientX - rect.x - 48,
        e.clientY - rect.y - 24,
      ]);
    }
  };

  useEffect(() => {
    if (!jsplumb) return;
    jsplumb.setZoom(1);
    jsplumb.unbind(EVENT_CONNECTION);
    jsplumb.bind(EVENT_CONNECTION, (payload, e) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {
        jsplumb.deleteConnection(payload.connection);
      };
      if (e) openEditTransitionModalRef.current(payload.connection);
    });
    jsplumb.unbind(EVENT_CONNECTION_DBL_CLICK);
    jsplumb.bind(EVENT_CONNECTION_DBL_CLICK, (connection) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {};
      openEditTransitionModalRef.current(connection);
    });
  }, [jsplumb, isActivated]);

  useEffect(() => {
    jsplumb?.batch(() => {
      jsplumb.deleteEveryConnection();
      snfa.transitions.forEach(([[inputState, inputSymbol], outputStates]) => {
        outputStates.forEach((outputState) => {
          jsplumb.connect({
            source: jsplumb.getManagedElement(inputState),
            target: jsplumb.getManagedElement(outputState),
            overlays: [
              {
                type: LabelOverlay.type,
                options: {
                  location: 0.5,
                  label: inputSymbol === "" ? "ε" : inputSymbol,
                  id: "inputSymbol",
                },
              },
              ...(!isActivated
                ? [
                    {
                      type: LabelOverlay.type,
                      options: {
                        location: 0,
                        label: "×",
                        events: {
                          click: () => {
                            rnfa.deleteTransition([
                              [inputState, inputSymbol],
                              outputState,
                            ]);
                          },
                        },
                      },
                    },
                  ]
                : []),
            ],
          });
        });
      });
    });
  }, [jsplumb, snfa, rnfa, isActivated]);

  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        ref={containerRef}
        onDoubleClick={dblClickAddState}
      >
        {snfa.states.map((state) => {
          const position = new Map(pnfa).get(state) || [0, 0];
          const tokens = new Map(fnfa).get(state) || [];
          return (
            <AutomatonState
              key={state}
              state={state}
              position={position}
              isInitialState={snfa.initialStates.includes(state)}
              isFinalState={snfa.finalStates.includes(state)}
              hasTokens={tokens.length > 0}
              hasEmptyToken={tokens.some((token) => token.length === 0)}
              jsplumb={jsplumb}
              onDoubleClick={() => dblClickRenameState(state)}
              onDelete={() => rnfa.deleteState(state)}
              updatePosition={(pos) => {
                rnfa.setPosition(state, pos);
              }}
            >
              <Stack>
                {tokens.map((token) => (
                  <Code key={token}>&nbsp;{token}&nbsp;</Code>
                ))}
              </Stack>
            </AutomatonState>
          );
        })}
      </Box>
      <EditTransitionModal
        rnfa={rnfa}
        openModalRef={openEditTransitionModalRef}
        closeModalRef={closeEditTransitionModalRef}
      />
      <EditStateModal
        rnfa={rnfa}
        openModalRef={openEditStateModalRef}
        closeModalRef={closeEditStateModalRef}
      />
    </>
  );
}
