import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Quiz } from '../panes/quiz/Quiz';

/**
 * A component containing zoom and reset buttons as well as a menu for additional options
 * @returns  A JSX element containing zoom and reset buttons as well as a menu for additional options
 */
export function Controls() {
  const { isOpen: isQuizModalOpen, onOpen: onQuizModalOpen, onClose: onQuizModalClose } = useDisclosure();
  const { isOpen: isTutorialModalOpen, onOpen: onTutorialModalOpen, onClose: onTutorialModalClose } = useDisclosure();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      position="absolute"
      bottom={0}
      left={0}
      paddingX={2}
      paddingY={3}
      zIndex={1}
      width="100%"
      data-testid="controls"
    >
      <Menu closeOnSelect={true} placement="top-end">
        <MenuButton
          as={IconButton}
          size="sm"
          isRound
          aria-label="More info"
          marginLeft="auto"
          icon={
            <QuestionOutlineIcon
              boxSize={6}
              css={{ "& circle": { display: "none" } }}
            />
          }
        />
        <Portal>
          <MenuList fontSize="sm" padding="0">
            <MenuItem onClick={onQuizModalOpen}>Quiz</MenuItem>
            <MenuItem onClick={onTutorialModalOpen}>Tutorial</MenuItem>
            <MenuItem as={Link} href="./docs" isExternal>Documentation</MenuItem>
          </MenuList>
        </Portal>
        <Portal>
          <Modal isOpen={isQuizModalOpen} onClose={onQuizModalClose} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Quiz</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Quiz />
              </ModalBody>
              <ModalFooter />
            </ModalContent>
          </Modal>
        </Portal>
        <Portal>
          <Modal isOpen={isTutorialModalOpen} onClose={onTutorialModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Tutorial</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <video controls width="100%">
                  <source src="./tutorial.mp4" type="video/mp4" />
                </video>
              </ModalBody>
              <ModalFooter />
            </ModalContent>
          </Modal>
        </Portal>
      </Menu>
    </Box>
  );
}
