import { useState } from "react";

import { ObjectMap } from "./util/ObjectMap";
import { PointXY } from "./util/PointXY";
import { AutomatonType, DataTM } from "./util/AutomatonType";

/**
 * An enumeration of possible TM directions
 */
export enum TMDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

/**
 * A Turing machine (TM) state
 */
export type TMState = string;
/**
 * A tape symbol in the TM's tape alphabet
 */
export type TMTapeSymbol = string;
/**
 * A TM Transition input: a state and a tape symbol
 */
export type TMTransitionInput = readonly [TMState, TMTapeSymbol];
/**
 * A TM Transition output: a state, a tape symbol and the direction
 */
export type TMTransitionOutput = readonly [TMState, TMTapeSymbol, TMDirection];
/**
 * A unique identifier for a TM, consisting of an array of tape symbols on the left, the current state and an array of tape symbols on the right
 */
export type TMID = readonly [
  readonly TMTapeSymbol[],
  TMState,
  readonly TMTapeSymbol[],
];

/**
 * Defination of TM
 */
export type TM = {
  /**
   * A finite set Q of states
   */
  readonly states: Set<TMState>;
  /**
   * A finite set Σ of symbols (the alphabet)
   */
  readonly symbols: Set<TMTapeSymbol>;
  /**
   * A finite set Γ of tape symbols s.t. Σ ⊆ Γ
   */
  readonly tapeSymbols: Set<TMTapeSymbol>;
  /**
   * A transition function δ ∈ Q × Γ → {stop} ∪ Q × Γ × {L, R}
   */
  readonly transitions: ObjectMap<
    TMTransitionInput,
    readonly TMTransitionOutput[]
  >;
  /**
   * An initial state q0 ∈ Q
   */
  initialState?: TMState;
  /**
   * The blank symbol B ∈ Γ but B ∈/ Σ.
   */
  blankSymbol?: TMTapeSymbol;
  /**
   * A set of final states F ⊆ Q
   */
  readonly finalStates: Set<TMState>;
};

/**
 * Defination of SerialTM
 */
export type SerialTM = {
  /**
   * The array of states in the TM
   */
  readonly states: readonly TMState[];
  /**
   * The array of symbols in the TM's alphabet
   */
  readonly symbols: readonly TMTapeSymbol[];
  /**
   * The array of tape symbols in the TM's tape alphabet
   */
  readonly tapeSymbols: readonly TMTapeSymbol[];
  /**
   * The array of transitions in the TM, where each transition is represented
   * as a tuple of a TMTransitionInput and an array of TMTransitionOutput
   */
  readonly transitions: readonly (readonly [
    TMTransitionInput,
    readonly TMTransitionOutput[],
  ])[];
  /**
   * The initial state of the TM
   */
  readonly initialState?: TMState;
  /**
   * The black symbol of the TM tape symbol
   */
  readonly blankSymbol?: TMTapeSymbol;
  /**
   * The array of final states in the TM
   */
  readonly finalStates: readonly TMState[];
};

/**
 * Hook that can be used to manipulate SerialTM
 */
export type HookSerialTM = {
  readonly setStates: React.Dispatch<React.SetStateAction<readonly TMState[]>>;
  readonly setSymbols: React.Dispatch<
    React.SetStateAction<readonly TMTapeSymbol[]>
  >;
  readonly setTapeSymbols: React.Dispatch<
    React.SetStateAction<readonly TMTapeSymbol[]>
  >;
  readonly setTransitions: React.Dispatch<
    React.SetStateAction<
      readonly (readonly [TMTransitionInput, readonly TMTransitionOutput[]])[]
    >
  >;
  readonly setInitialState: React.Dispatch<React.SetStateAction<TMState>>;
  readonly setBlankSymbol: React.Dispatch<React.SetStateAction<TMTapeSymbol>>;
  readonly setFinalStates: React.Dispatch<
    React.SetStateAction<readonly TMState[]>
  >;
};

/**
 * A mapping from TMState to PointXY
 */
export type PositionTableTM = ObjectMap<TMState, PointXY>;
/**
 * An array of tuples, where each tuples contains a TMState and its corresponding PointXY position
 */
export type PositionsTM = readonly (readonly [TMState, PointXY])[];
/**
 * Hook of PositionsTM
 */
export type HookPositionsTM = React.Dispatch<React.SetStateAction<PositionsTM>>;

/**
 * Defination of ReactiveTM
 */
export class ReactiveTM {
  /**
   * A Turing machine (TM)
   */
  private tm: TM;
  /**
   * A hook of SerialTM
   */
  private readonly hook: HookSerialTM;
  /**
   * A Position Table of TM
   */
  private pttm: PositionTableTM;
  /**
   * A hook of positions of TM
   */
  private readonly hookPTM: HookPositionsTM;

  /**
   * Contructs a ReactiveTM
   * @param tm A Turing machine (TM)
   * @param hook A hook of SerialTM
   * @param pttm A Position Table of TM
   * @param hookPTM A hook of positions of TM
   */
  public constructor(
    tm: TM,
    hook: HookSerialTM,
    pttm: PositionTableTM,
    hookPTM: HookPositionsTM,
  ) {
    this.tm = tm;
    this.hook = hook;
    this.pttm = pttm;
    this.hookPTM = hookPTM;
  }

  /**
   * Imports data to the TM
   * @param param0 The Data to be imported
   */
  public importData(
    {
      automaton: {
        states,
        symbols,
        tapeSymbols,
        transitions,
        initialState,
        blankSymbol,
        finalStates,
      },
      positions,
    }: DataTM = {
      type: AutomatonType.TM,
      automaton: {
        states: [],
        symbols: [],
        tapeSymbols: [],
        transitions: [],
        finalStates: [],
      },
      positions: [],
    },
  ): void {
    this.tm = {
      states: new Set(states),
      symbols: new Set(symbols),
      tapeSymbols: new Set(tapeSymbols),
      transitions: new ObjectMap(transitions),
      initialState: initialState,
      blankSymbol: blankSymbol,
      finalStates: new Set(finalStates),
    };
    this.pttm = new ObjectMap(positions);

    this.hook.setStates(Array.from(this.tm.states));
    this.hook.setSymbols(Array.from(this.tm.symbols));
    this.hook.setTapeSymbols(Array.from(this.tm.tapeSymbols));
    this.hook.setTransitions(Array.from(this.tm.transitions));
    this.hook.setInitialState(this.tm.initialState);
    this.hook.setBlankSymbol(this.tm.blankSymbol);
    this.hook.setFinalStates(Array.from(this.tm.finalStates));
    this.hookPTM(Array.from(this.pttm));
  }

  /**
   * Adds a state to the TM
   * @param state A TM state
   */
  public addState(state: TMState): void {
    this.tm.states.add(state);
    this.hook.setStates(Array.from(this.tm.states));

    this.pttm.set(state, [0, 0]);
    this.hookPTM(Array.from(this.pttm));
  }

  /**
   * Deletes a state in the TM
   * @param state A TM state
   */
  public deleteState(state: TMState): void {
    this.tm.states.delete(state);
    this.hook.setStates(Array.from(this.tm.states));

    for (const [[inputState, inputSymbol], output] of this.tm.transitions) {
      if (inputState === state) {
        this.tm.transitions.delete([inputState, inputSymbol]);
      } else {
        const outputAfterDeletion = output.filter(
          ([outputState]) => outputState !== state,
        );
        this.tm.transitions.set([inputState, inputSymbol], outputAfterDeletion);
      }
    }
    this.hook.setTransitions(Array.from(this.tm.transitions));

    if (this.tm.initialState === state) delete this.tm.initialState;
    this.hook.setInitialState(this.tm.initialState);

    this.tm.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.tm.finalStates));
  }

  /**
   * Renames a state in the TM
   * @param oldState The old TM state
   * @param newState The new TM state
   * @returns Nothing if the new TM state is already existed
   */
  public renameState(oldState: TMState, newState: TMState): void {
    if (this.tm.states.has(newState)) return;
    // create a new state
    this.addState(newState);
    this.setPosition(newState, this.pttm.get(oldState));
    if (this.tm.initialState === oldState) this.setInitialState(newState);
    if (this.tm.finalStates.has(oldState)) this.addFinalState(newState);
    // migrate transitions from and to the state
    for (const [[oldInputState, inputSymbol], oldOutput] of this.tm
      .transitions) {
      const isInput = oldInputState === oldState;
      const isOutput = oldOutput.some(
        ([oldOutputState]) => oldOutputState === oldState,
      );
      if (isInput || isOutput) {
        const newInputState: TMState = isInput ? newState : oldInputState;
        const newOutput: readonly TMTransitionOutput[] = oldOutput.map(
          ([outputState, outputSymbol, outputDirection]) => [
            outputState === oldState ? newState : outputState,
            outputSymbol,
            outputDirection,
          ],
        );
        for (const output of oldOutput) {
          this.deleteTransition([[oldInputState, inputSymbol], output]);
        }
        for (const output of newOutput) {
          this.addTransition([[newInputState, inputSymbol], output]);
        }
      }
    }
    // delete the old state
    this.deleteState(oldState);
  }

  /**
   * Adds a symbol to the TM
   * @param symbol A TM symbol
   */
  public addSymbol(symbol: TMTapeSymbol): void {
    this.tm.symbols.add(symbol);
    this.hook.setSymbols(Array.from(this.tm.symbols));
  }

  /**
   * Deletes a tape symbol in the TM
   * @param symbol A TM tape symbol
   */
  public deleteSymbol(symbol: TMTapeSymbol): void {
    this.tm.symbols.delete(symbol);
    this.hook.setSymbols(Array.from(this.tm.symbols));
  }

  /**
   * Adds a tape symbol to the TM
   * @param symbol A TM tape symbol
   */
  public addTapeSymbol(symbol: TMTapeSymbol): void {
    this.tm.tapeSymbols.add(symbol);
    this.hook.setTapeSymbols(Array.from(this.tm.tapeSymbols));
  }

  /**
   * Deletes a tape symbol in the TM
   * @param symbol A TM tape symbol
   */
  public deleteTapeSymbol(symbol: TMTapeSymbol): void {
    this.tm.tapeSymbols.delete(symbol);
    this.hook.setTapeSymbols(Array.from(this.tm.tapeSymbols));

    this.tm.symbols.delete(symbol);
    this.hook.setSymbols(Array.from(this.tm.symbols));

    for (const [[inputState, inputSymbol], output] of this.tm.transitions) {
      if (inputSymbol === symbol) {
        this.tm.transitions.delete([inputState, inputSymbol]);
      } else {
        const outputAfterDeletion = output.filter(
          ([, outputSymbol]) => outputSymbol !== symbol,
        );
        this.tm.transitions.set([inputState, inputSymbol], outputAfterDeletion);
      }
    }
    this.hook.setTransitions(Array.from(this.tm.transitions));
  }

  /**
   * Adds a transition to the TM
   * @param param0 A TMTransition
   * @returns Nothing if the TM transition is not valid
   */
  public addTransition([input, [outputState, outputSymbol, outputDirection]]: [
    TMTransitionInput,
    TMTransitionOutput,
  ]): void {
    const outputBeforeAddition = this.tm.transitions.get(input) || [];
    const outputInTM = outputBeforeAddition.filter(
      ([elOutputState, elOutputSymbol, elOutputDirection]) =>
        elOutputState === outputState &&
        elOutputSymbol === outputSymbol &&
        elOutputDirection === outputDirection,
    );
    if (outputInTM.length > 0) return;
    this.tm.transitions.set(
      input,
      outputBeforeAddition.concat([
        [outputState, outputSymbol, outputDirection],
      ]),
    );
    this.hook.setTransitions(Array.from(this.tm.transitions));
  }

  /**
   * Deletes a transition in the TM
   * @param param0 A TMTransition
   */
  public deleteTransition([
    input,
    [outputState, outputSymbol, outputDirection],
  ]: [TMTransitionInput, TMTransitionOutput]): void {
    const outputBeforeDeletion = this.tm.transitions.get(input) || [];
    const outputAfterDeletion = outputBeforeDeletion.filter(
      ([elOutputState, elOutputSymbol, elOutputDirection]) =>
        !(
          elOutputState === outputState &&
          elOutputSymbol === outputSymbol &&
          elOutputDirection === outputDirection
        ),
    );
    if (outputAfterDeletion.length === 0) {
      this.tm.transitions.delete(input);
    } else {
      this.tm.transitions.set(input, outputAfterDeletion);
    }
    this.hook.setTransitions(Array.from(this.tm.transitions));
  }

  /**
   * Modifies a transition in the TM
   * @param param0 The TM Transition
   * @param newInputSymbol The new input symbol
   * @param newOutputSymbol The new output symbol
   * @param newOutputDirection The new output direction
   * @returns Nothing if the input or output symbol is invalid
   */
  public modifyTransition(
    [[inputState, inputSymbol], [outputState, outputSymbol, outputDirection]]: [
      TMTransitionInput,
      TMTransitionOutput,
    ],
    newInputSymbol: TMTapeSymbol,
    newOutputSymbol: TMTapeSymbol,
    newOutputDirection: TMDirection,
  ): void {
    if (!this.tm.tapeSymbols.has(newInputSymbol)) return;
    if (!this.tm.tapeSymbols.has(newOutputSymbol)) return;
    this.deleteTransition([
      [inputState, inputSymbol],
      [outputState, outputSymbol, outputDirection],
    ]);
    this.addTransition([
      [inputState, newInputSymbol],
      [outputState, newOutputSymbol, newOutputDirection],
    ]);
  }

  /**
   * Sets an initial state to the TM
   * @param state A TM state
   */
  public setInitialState(state: TMState): void {
    if (this.tm.states.has(state)) this.tm.initialState = state;
    this.hook.setInitialState(this.tm.initialState);
  }

  /**
   * Sets the blank symbol to the TM
   * @param symbol A TM tape symbol
   */
  public setBlankSymbol(symbol: TMTapeSymbol): void {
    if (this.tm.tapeSymbols.has(symbol)) this.tm.blankSymbol = symbol;
    this.hook.setBlankSymbol(this.tm.blankSymbol);
  }

  /**
   * Adds a final state to the TM
   * @param state A TM state
   */
  public addFinalState(state: TMState): void {
    this.tm.finalStates.add(state);
    this.hook.setFinalStates(Array.from(this.tm.finalStates));
  }

  /**
   * Deletes a final state in the TM
   * @param state A TM state
   */
  public deleteFinalState(state: TMState): void {
    this.tm.finalStates.delete(state);
    this.hook.setFinalStates(Array.from(this.tm.finalStates));
  }

  /**
   * Gets the position of a TM state
   * @param state A TM state
   * @returns The position of the given TM state
   */
  public getPosition(state: TMState): PointXY {
    return this.pttm.get(state);
  }

  /**
   * Sets the position of the given TM state
   * @param state A TM state
   * @param position The position of the TM state
   * @returns Nothing if the TM state doesn't exist
   */
  public setPosition(state: TMState, position: PointXY): void {
    if (!this.pttm.has(state)) return;
    this.pttm.set(state, position);
    this.hookPTM(Array.from(this.pttm));
  }
}

/**
 * Hook that returns a ReactiveTM instance from the given SerialTM and previous PTM
 * @returns A tuple containing the SerialTM, ReactiveTM and PositionsTM instances
 */
export function useReactiveTM(): [SerialTM, ReactiveTM, PositionsTM] {
  const [states, setStates] = useState<TMState[]>([]);
  const [symbols, setSymbols] = useState<TMTapeSymbol[]>([]);
  const [tapeSymbols, setTapeSymbols] = useState<TMTapeSymbol[]>([]);
  const [transitions, setTransitions] = useState<
    [TMTransitionInput, TMTransitionOutput[]][]
  >([]);
  const [initialState, setInitialState] = useState<TMState>();
  const [blankSymbol, setBlankSymbol] = useState<TMTapeSymbol>();
  const [finalStates, setFinalStates] = useState<TMState[]>([]);
  const [ptm, setPTM] = useState<[TMState, PointXY][]>([]);

  /**
   * The SerialTM instance
   */
  const stm: SerialTM = {
    states,
    symbols,
    tapeSymbols,
    transitions,
    initialState,
    blankSymbol,
    finalStates,
  };

  /**
   * The HookSerialTM intance
   */
  const hook: HookSerialTM = {
    setStates,
    setSymbols,
    setTapeSymbols,
    setTransitions,
    setInitialState,
    setBlankSymbol,
    setFinalStates,
  };

  /**
   * The TM instance
   */
  const tm: TM = {
    states: new Set(states),
    symbols: new Set(symbols),
    tapeSymbols: new Set(tapeSymbols),
    transitions: new ObjectMap(transitions),
    initialState: initialState,
    blankSymbol: blankSymbol,
    finalStates: new Set(finalStates),
  };

  /**
   * The PositionTableTM instance
   */
  const pttm: PositionTableTM = new ObjectMap(ptm);

  /**
   * The ReactiveTM instance
   */
  const rtm: ReactiveTM = new ReactiveTM(tm, hook, pttm, setPTM);

  return [stm, rtm, ptm];
}
