import React, { useEffect, useState } from "react";
import { AnswerObject } from "./Quiz";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';

type QuestionCardProps = {
  question: string;
  questionImage: string;
  answers: string[];
  callback: (selectedAnswer: string) => void;
  userAnswer: AnswerObject | undefined;
  questionNr: number;
  totalQuestions: number;
};

/**
 * A component containing the question
 * @param param0
 * @returns A JSX component containing the question
 */
export function QuestionCard({
  question,
  questionImage,
  answers,
  callback,
  userAnswer,
  questionNr,
  totalQuestions,
}: QuestionCardProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    setSelectedAnswer("");
    setSubmitted(false);
  }, [question]);

  const handleAnswer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSubmitted(true);
    callback(selectedAnswer);
  };

  const handleAnswerSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const answer = e.currentTarget.value;
    setSelectedAnswer(answer);
  };

  return (
    <Box maxW="3xl" overflow="hidden">
          <Heading
            as="h2"
            fontSize="l"
            marginBottom="0.5rem"
            textTransform="uppercase"
            textAlign="center"
          >
            Question: {questionNr} / {totalQuestions}
          </Heading>
          <Text
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xl"
            ml="2"
            textAlign="center"
            padding="5px"
            colorScheme="teal"
          >
            {question}
          </Text>
          <Center>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              <Image
                src={questionImage}
                objectFit="contain"
                maxW="100%"
                htmlWidth="auto"
                htmlHeight="auto"
                loading="lazy"
              />
            </Box>
          </Center>

          <RadioGroup>
            <VStack>
              {answers.map((answer, index) => (
                <Radio
                  key={index}
                  value={answer}
                  isChecked={answer === selectedAnswer}
                  isDisabled={submitted}
                  onChange={handleAnswerSelect}
                  backgroundColor="white"
                  padding="3px"
                >
                  {answer}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>

          <Flex justify="center" padding="15px">
            <Button
              onClick={handleAnswer}
              isDisabled={!selectedAnswer || submitted}
              size="md"
              colorScheme="teal"
            >
              Submit
            </Button>
          </Flex>
    </Box>
  );
}
