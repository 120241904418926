import { useState } from "react";
import {
  NFAState,
  NFATransitionInput,
  NFATransitionOutput,
  SerialNFA,
} from "./ReactiveNFA";
import { ObjectMap } from "./util/ObjectMap";

/**
 * Type representing a frame of a NFA state and input strings
 */
export type FrameNFA = readonly (readonly [NFAState, readonly string[]])[];

/**
 * Type representing an animation of an NFA, with functions for controlling the animation
 */
export type AnimationNFA = {
  init: (input: string) => void;
  stop: () => void;
  forward: () => void;
  backward: () => void;
  isValid: () => boolean;
  isPassed: () => boolean;
  isFailed: () => boolean;
};

/**
 * Check if the NFA is valid
 * @param snfa a SerialNFA
 * @returns if the NFA is valid
 */
export function validateNFA(snfa: SerialNFA): boolean {
  // TODO: validate initialStates and finalStates
  const areTransitionsValid = [...snfa.transitions].every(
    ([[inputState, inputSymbol], outputStates]) =>
      snfa.states.includes(inputState) &&
      [...outputStates].every((outputState) =>
        snfa.states.includes(outputState),
      ) &&
      snfa.inputSymbols.includes(inputSymbol),
  );
  return areTransitionsValid;
}

/**
 * Computes the next frame of the NFA animation given the last frame and the SerialNFA object
 * @param snfa SerialNFA object representing the NFA
 * @param lastFrame Last frame of the NFA animation
 * @returns Next frame of the NFA animation
 */
export function nextFrameNFA(snfa: SerialNFA, lastFrame: FrameNFA): FrameNFA {
  const frame = new ObjectMap<NFAState, readonly string[]>();
  const inputSymbols = snfa.inputSymbols;
  const transitions = new ObjectMap<
    NFATransitionInput,
    readonly NFATransitionOutput[]
  >(snfa.transitions);

  for (const [inputState, inputs] of lastFrame) {
    for (const inputString of inputs) {
      for (const inputSymbol of inputSymbols) {
        if (inputString.startsWith(inputSymbol)) {
          const outputStates = transitions.get([inputState, inputSymbol]);
          if (outputStates?.length > 0) {
            for (const outputState of outputStates) {
              const outputInputs = new Set(frame.get(outputState));
              outputInputs.add(inputString.replace(inputSymbol, ""));
              frame.set(outputState, Array.from(outputInputs));
            }
          }
        }
      }
    }
  }
  return Array.from(frame);
}

/**
 *  Hook that returns the current frame of the NFA animation and functions for controlling the animation
 * @param snfa SerialNFA object representing the BFA
 * @returns Array containing the current frame of the NFA animation and functions for controlling the animation
 */
export function useAnimationNFA(snfa: SerialNFA): [FrameNFA, AnimationNFA] {
  const [fnfa, setFNFA] = useState<FrameNFA>([]);
  const [hnfa, setHNFA] = useState<readonly FrameNFA[]>([]);

  /**
   * Initializes the NFA animation with a given input string
   * @param input The given input string
   */
  const init = (input: string) => {
    setFNFA(snfa.initialStates.map((state) => [state, [input]]));
    setHNFA([]);
  };

  /**
   * Stops the NFA animation
   */
  const stop = () => {
    if (fnfa.length > 0) setFNFA([]);
    if (hnfa.length > 0) setHNFA([]);
  };

  /**
   * Moves the NFA animation forward by one frame
   */
  const forward = () => {
    const nextFrame = nextFrameNFA(snfa, fnfa);
    const history = Array.from(hnfa);
    history.push(fnfa);
    setFNFA(nextFrame);
    setHNFA(history);
  };

  /**
   * Moves the NFA animation backward by one frame
   * @returns Nothing if the last frame doesn't exist
   */
  const backward = () => {
    const history = Array.from(hnfa);
    const lastFrame = history.pop();
    if (!lastFrame) return;
    setFNFA(lastFrame);
    setHNFA(history);
  };

  /**
   * Checks the validity of the NFA
   * @returns The validity of the NFA
   */
  const isValid = () => validateNFA(snfa);

  /**
   * Checks the result if an input string can pass the input test of the NFA
   * @returns True if the input string is passed, false otherwise
   */
  const isPassed = () =>
    fnfa.some(
      ([state, inputs]) =>
        snfa.finalStates.includes(state) &&
        inputs.some((token) => token.length === 0),
    );

  /**
   * Checks the result of the input test is failed
   * @returns True if the input string is failed, false otherwise
   */
  const isFailed = () =>
    // has no token
    fnfa.length === 0 ||
    // is same as the last frame
    JSON.stringify(fnfa) === JSON.stringify(hnfa[-1]) ||
    // has all states failed
    fnfa.every(
      ([state, inputs]) =>
        !snfa.finalStates.includes(state) &&
        inputs.some((token) => token.length === 0),
    ) ||
    // has a loop
    hnfa.some((frame) => JSON.stringify(frame) === JSON.stringify(fnfa));

  return [
    fnfa,
    {
      init,
      stop,
      forward,
      backward,
      isValid,
      isPassed,
      isFailed,
    },
  ];
}
