/**
 * The GridPattern component containing a grid layout
 * @returns A JSX component containing a grid layout
 */
export function GridPattern() {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    >
      <pattern
        id="grid"
        width="24"
        height="24"
        x="0"
        y="0"
        patternUnits="userSpaceOnUse"
      >
        <path
          d="M 24 0 L 0 0 0 24"
          fill="none"
          stroke="var(--chakra-colors-chakra-border-color)"
          strokeWidth="1"
        ></path>
      </pattern>
      <rect width="100%" height="100%" fill="url('#grid')"></rect>
    </svg>
  );
}
