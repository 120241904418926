import { useContext, useEffect, useRef, useState } from "react";
import { AutomatonState } from "../../components/AutomatonState";

import {
  DFAInputSymbol,
  DFAState,
  PositionsDFA,
  ReactiveDFA,
  SerialDFA,
} from "../../../lib/ReactiveDFA";
import {
  Box,
  Button,
  Code,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useReactiveJSPlumb } from "../../../lib/jsplumb/ReactiveJSPlumb";
import { Connection, EVENT_CONNECTION, LabelOverlay } from "@jsplumb/core";
import { EVENT_CONNECTION_DBL_CLICK } from "@jsplumb/browser-ui";
import { FrameDFA } from "../../../lib/AnimationDFA";
import { ActivationContext } from "../../../lib/util/ActivationContext";

/**
 * A component containing the modal to edit the transition
 * @param param0
 * @returns A JSX component containing the modal to edit the transition
 */
function EditTransitionModal({
  rdfa,
  openModalRef,
  closeModalRef,
}: {
  rdfa: ReactiveDFA;
  openModalRef: React.MutableRefObject<(conn: Connection) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<DFAInputSymbol>("");
  const [connection, setConnection] = useState<Connection>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    const label = (connection?.overlays?.inputSymbol as LabelOverlay)?.labelText;
    if (label) {
      rdfa.modifyTransitionSymbol(
        [[connection.sourceId, label], connection.targetId],
        input,
      );
    } else {
      rdfa.addTransition([[connection.sourceId, input], connection.targetId]);
    }
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (conn: Connection) => {
      const label =
        (conn?.overlays?.inputSymbol as LabelOverlay)?.labelText || "";
      if (label) setInput(label);
      setConnection(conn);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Transition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Source</FormLabel>
            <Input value={connection?.sourceId} disabled />
            <FormLabel>Target</FormLabel>
            <Input value={connection?.targetId} disabled />
            <FormLabel>Symbol</FormLabel>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the modal to edit the state
 * @param param0
 * @returns A JSX component containing the modal to edit the state
 */
function EditStateModal({
  rdfa,
  openModalRef,
  closeModalRef,
}: {
  rdfa: ReactiveDFA;
  openModalRef: React.MutableRefObject<(state: DFAState) => void>;
  closeModalRef: React.MutableRefObject<() => void>;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<DFAState>("");
  const [state, setState] = useState<DFAState>();

  const onCancel = () => {
    closeModalRef.current();
    onClose();
  };

  const onSubmit = () => {
    rdfa.renameState(state, input);
    closeModalRef.current();
    onClose();
  };

  useEffect(() => {
    openModalRef.current = (state: DFAState) => {
      setInput(state);
      setState(state);
      onOpen();
    };
  }, [onOpen, openModalRef]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit State</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" ? onSubmit() : null}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * A component containing the DFA visually
 * @param param0 The SerialDFA, ReactiveDFA, PositionsDFA and FrameDFA instances
 * @returns A JSX component containing the DFA visually
 */
export function VisualDFA({
  sdfa,
  rdfa,
  pdfa,
  fdfa,
}: {
  sdfa: SerialDFA;
  rdfa: ReactiveDFA;
  pdfa: PositionsDFA;
  fdfa: FrameDFA;
}) {
  const isActivated = useContext(ActivationContext);

  const containerRef = useRef<HTMLDivElement>();
  const jsplumb = useReactiveJSPlumb(containerRef);

  const openEditTransitionModalRef = useRef<(conn: Connection) => void>(
    () => {},
  );
  const closeEditTransitionModalRef = useRef<() => void>(() => {});

  const openEditStateModalRef = useRef<(state: DFAState) => void>(() => {});
  const closeEditStateModalRef = useRef<() => void>(() => {});

  const dblClickRenameState = (state: DFAState) => {
    if (isActivated) return;
    openEditStateModalRef.current(state);
  };

  const dblClickAddState = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isActivated) return;
    if (e.target === containerRef.current) {
      let number = 0;
      while (sdfa.states.includes(`q${number}`)) number++;
      const state = `q${number}`;
      const rect = containerRef.current.getBoundingClientRect();
      rdfa.addState(state);
      rdfa.setPosition(state, [
        e.clientX - rect.x - 48,
        e.clientY - rect.y - 24,
      ]);
    }
  };

  useEffect(() => {
    if (!jsplumb) return;
    jsplumb.setZoom(1);
    jsplumb.unbind(EVENT_CONNECTION);
    jsplumb.bind(EVENT_CONNECTION, (payload, e) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {
        jsplumb.deleteConnection(payload.connection);
      };
      if (e) openEditTransitionModalRef.current(payload.connection);
    });
    jsplumb.unbind(EVENT_CONNECTION_DBL_CLICK);
    jsplumb.bind(EVENT_CONNECTION_DBL_CLICK, (connection) => {
      if (isActivated) return;
      closeEditTransitionModalRef.current = () => {};
      openEditTransitionModalRef.current(connection);
    });
  }, [jsplumb, isActivated]);

  useEffect(() => {
    jsplumb?.batch(() => {
      jsplumb.deleteEveryConnection();
      sdfa.transitions.forEach(([[inputState, inputSymbol], outputState]) => {
        jsplumb.connect({
          source: jsplumb.getManagedElement(inputState),
          target: jsplumb.getManagedElement(outputState),
          overlays: [
            {
              type: LabelOverlay.type,
              options: {
                location: 0.5,
                label: inputSymbol,
                id: "inputSymbol",
              },
            },
            ...(!isActivated
              ? [
                  {
                    type: LabelOverlay.type,
                    options: {
                      location: 0,
                      label: "×",
                      events: {
                        click: () => {
                          rdfa.deleteTransition([[inputState, inputSymbol]]);
                        },
                      },
                    },
                  },
                ]
              : []),
          ],
        });
      });
    });
  }, [jsplumb, sdfa, rdfa, isActivated]);

  return (
    <>
      <Box
        position="relative"
        height="100%"
        width="100%"
        ref={containerRef}
        onDoubleClick={dblClickAddState}
      >
        {sdfa.states.map((state) => {
          const position = new Map(pdfa).get(state) || [0, 0];
          const tokens = new Map(fdfa).get(state) || [];
          return (
            <AutomatonState
              key={state}
              state={state}
              position={position}
              isInitialState={sdfa.initialState === state}
              isFinalState={sdfa.finalStates.includes(state)}
              hasTokens={tokens.length > 0}
              hasEmptyToken={tokens.some((token) => token.length === 0)}
              jsplumb={jsplumb}
              onDoubleClick={() => dblClickRenameState(state)}
              onDelete={() => rdfa.deleteState(state)}
              updatePosition={(pos) => {
                rdfa.setPosition(state, pos);
              }}
            >
              <Stack>
                {tokens.map((token) => (
                  <Code key={token}>&nbsp;{token}&nbsp;</Code>
                ))}
              </Stack>
            </AutomatonState>
          );
        })}
      </Box>
      <EditTransitionModal
        rdfa={rdfa}
        openModalRef={openEditTransitionModalRef}
        closeModalRef={closeEditTransitionModalRef}
      />
      <EditStateModal
        rdfa={rdfa}
        openModalRef={openEditStateModalRef}
        closeModalRef={closeEditStateModalRef}
      />
    </>
  );
}
